.paging {
	padding: 16px 0 80px 0;
}

.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	background: $wh;
	box-shadow: 4px 4px 8px rgba(99, 100, 102, 0.25);
	border-radius: 53px;
	height: 48px;
	padding: 12px 40px;
	> li {
		display: flex;
		justify-content: center;
		padding: 8px 8px;
		margin: 0 7px;
		width: 40px;
		> a,
		> span {
			color: $dg;
			font-size: 16px;
			line-height: 24px;
			&:hover {
				text-decoration: none;
				text-underline: none;
			}
		}
		&.active, &:hover {
			background: linear-gradient(149.96deg, #4CAF50 12.84%, #4DB45E 12.85%, #26A6D1 94.74%);
			border-radius: 31px;
			cursor: pointer;
			> a, span {
				color: $wh;
				text-decoration: none;
				text-underline: none;
			}
		}
		&:first-child {
			> a,
			> span {
				margin-left: 0;
				//@include border-left-radius($border-radius-base);
			}
		}
	}

	> .disabled {
		> span,
		> span:hover,
		> span:focus,
		> a,
		> a:hover,
		> a:focus {
			color: $pagination-disabled-color;
			background-color: $pagination-disabled-bg;
			border-color: $pagination-disabled-border;
			cursor: $cursor-disabled;
		}
	}
}

// Sizing
// --------------------------------------------------

// Large
.pagination-lg {
	@include pagination-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $border-radius-large);
}

// Small
.pagination-sm {
	@include pagination-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $border-radius-small);
}
