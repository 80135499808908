/*
 * Off Canvas
 * --------------------------------------------------
 */
@media screen and (max-width: $screen-md-min) {
	.row-offcanvas {
		position: relative;
		@include transition(all 0.25s ease-out);
	}
	.row-offcanvas-right {
		right: 0;
	}
	.row-offcanvas-left {
		left: 0;
	}
	.row-offcanvas-right
	.sidebar-offcanvas {
		right: -70%;
	}
	.row-offcanvas-left
	.sidebar-offcanvas {
		left: -70%;
	}
	.row-offcanvas-right.active {
		right: 70%;
	}
	.row-offcanvas-left.active {
		left: 70%;
	}
	.sidebar-offcanvas {
		position: absolute;
		top: 0;
		width: 70%;
		height: 100%;
		ul, ol {
			padding: 0;
			li {
				list-style: none;
			}
		}
	}
	.bottom-offcanvas {
		border-top: 1px solid $border-color-base;
		padding: 10px 0;
	}
}

/*offcanvas-menu*/
#offcanvasmenu {
	.nav .caret {
		border-bottom-color: $white;
		border-top-color: $white;
		margin-left: 5px;
		display: none !important;
	}
	.pavo-widget .widget-heading {
		margin-top: 15px;
	}
	li {
		.fa {
			color: $bk;
			font-size: 22px;
			line-height: 1;
		}
		.fa-minus-square::before {
			content: "\f28e";
		}
		.fa-plus-square::before {
			content: "\f2c2";
		}
	}
	.navbar-nav {
		float: none;
		margin: 0;
		> li {
			float: none;
			background-color: $white;
			border-right: none;
			&:hover:after {
				display: none;
			}
			.click-canavs-menu {
				cursor: pointer;
				display: inline-block;
				padding: 11px 10px;
				position: absolute;
				right: 0;
				z-index: 1;
			}
			li.dropdown-submenu {
				.click-canavs-menu {
					padding: 10px 25px;
				}
				li.dropdown-submenu {
					> .click-canavs-menu {
						padding: 10px 15px;
					}
				}
			}
			> a {
				font-weight: 600;
				font-size: 16px;
				display: block;
				@include box-shadow(inset 0 -1px rgba(0, 0, 0, 0.2));
				text-transform: uppercase;
				color: $bk;
				padding-left: 15px;
			}
		}
	}
	.dropdown-menu {
		border: 0 none;
		border-radius: 0;
		box-shadow: none;
		display: none;
		float: none;
		margin: 0;
		position: static;
		visibility: visible;
		width: auto !important;
		min-width: 160px;
		@include opacity (1);
		.mega-col {
			padding: 0;
		}
		.row {
			margin: 0;
		}
		a {
			font-size: 13px;
			padding: 5px 0;
			display: block;
			color: $text-color;
			&:hover {
				color: $theme-color;
			}
		}
	}
	.w-product {
		padding: 0;
	}
	.product-block {
		border: none;
		padding: 0;
		.image {
			float: none;
			margin: 0;
		}
	}
	.cal_all {
		li {
			padding: 5px 20px;
		}
	}
}

.canvas-menu {
	margin: 0;
	background: transparent;
	color: $tc;
	font-size: 28px;
	padding: 0 10px;
	border: none;
}