// banner builder
.banner-wrapper-margin {
	.banner-wrapper {
		margin-bottom: 20px;
	}
}

.image-item-margin {
	.image-item {
		margin-bottom: 20px;
	}
}

.nomargin .box {
	margin-bottom: 0;
}

/* slidelayer */
.layerslider-wrapper {
	overflow: hidden;
	&:hover {
		.tp-rightarrow {
			right: 20px !important;
		}
		.tp-leftarrow {
			left: 20px !important;
		}
	}
}

.tparrows {
	position: absolute;
	top: 50% !important;
	z-index: 9999;
	cursor: pointer;
	display: block;
	@include size(45px, 45px);
	@include transition(all 0.3s ease-in-out 0s);
	font-size: 0;
	&:before {
		content: "";
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		z-index: 12;
		@include size(40px, 35px);
		@include transition(all 0.35s ease 0s);
		display: block;
	}
	&.tp-rightarrow {
		&:before {
			background-position: -50px -2px;
		}
	}
}

.tp-bullets.simplebullets .bullet:hover,
.tp-bullets.simplebullets .bullet.selected {
	background-color: $theme-color;
}

.tp-bullets.simplebullets.round .bullet:hover,
.tp-bullets.simplebullets.round .bullet.selected,
.tp-bullets.simplebullets.navbar .bullet:hover,
.tp-bullets.simplebullets.navbar .bullet.selected {
	background-color: $theme-color;
}

.effect-carousel-v2 {
	.carousel-controls-v1 {
		position: absolute;
		right: 0;
		.carousel-control {
			@include opacity(0);
			@include transition(all 0.5s ease-in-out 0s);
			top: -20px;
			&.left {
				left: -63px;
			}
		}
	}
	&:hover {
		.carousel-control {
			@include opacity(1);
			top: 2px;
		}
	}
}

.effect-carousel {
	.carousel-control {
		@include opacity(0);
		@include transition(all 0.5s ease-in-out 0s);
		&.left {
			left: -45px;
		}
		&.right {
			right: -45px;
		}
	}
	&:hover {
		.carousel-control {
			@include opacity(1);
			&.left {
				left: 0;
			}
			&.right {
				right: 0;
			}
		}
	}
}

/* tablist-v1 */
.tablist-v1 {
	.product-grid {
		.products-row {
			.product-col {
				&:last-child {
					border-right: 1px solid $border-color;
					@include rounded-corners(3px);
				}
			}
		}
	}
}
//featured categories
.featured-categories {
	width: 100%;
	.panel-body {
		overflow: hidden;
		padding: 0;
		.feature-cat {
			border-radius: 10px;
			padding: 8px;
			width: 24.5%;
			height: 100%;
			overflow: hidden;
			.image {
				position: relative;
				overflow: hidden;
				border-radius: 10px;
				.img-responsive {
					box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
					border-radius: 10px;
					height: inherit;
					max-width: unset;
				}
				.caption {
					position: absolute;
					width: 100%;
					height: 56px;
					bottom: 0;
					left: 0;
					background: linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, #202020 100%);
					border-radius: 0 0 10px 10px;
					padding: 0 16px;
					&:hover {
						background: $d;
						a {
							text-decoration: none;
						}
					}
					a.p1, p {
						color: $wh;
						margin: 0;
						width: 100%;
					}
				}
			}
			&:nth-child(6n+1), &:nth-child(6n+6) {
				width: 49%;
				.image {
					.img-responsive {
						width: 100%;
					}
				}
			}
			&:nth-child(2) .image > a {
				position: relative;
				height: 378px;
				left: -200px;
			}
			&:nth-child(3) .image > a {
				position: relative;
				left: -125px;
			}
			&:nth-child(4) .image > a {
				position: relative;
				left: -70px;
			}
			&:nth-child(5) .image > a {
				position: relative;
				left: -275px;
			}
			&:nth-child(6) .image > a {

			}
		}
	}
}
//pav category tabs on homepage
.page-home {
	.pav-categorytab {
		.nav-pills {
			position: relative;
			padding: 32px 0 0 0;
		}
		.show_all {
			margin: 24px 0 0;
			a {
				@include size(175px, 40px);
				background: linear-gradient(172.51deg, #4CAF50 12.84%, #4DB45E 12.85%, #26A6D1 94.74%);
				border-radius: 59px;
				color: $wh;
				&:hover, &:active {
					text-decoration: none;
					background: $tc;
				}
			}
		}
		.owl-nav {
			height: 40px;
			display: flex;
			align-items: center;
			position: absolute;
			right: 110px;
			top: 32px;
			.owl-prev {
				svg {
					transform: rotate(180deg);
				}
			}
			.owl-next, .owl-prev {
				@include size(40px, 40px);
				border-radius: 20px;
				margin-left: 16px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: $whwg;
				box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
				svg {
					@include size(20px, 20px);
					path {
						fill: $dg;
					}
				}
				&:hover {
					svg {
						path {
							fill: $tc;
						}
					}
				}
			}
		}
		.mcarousel {
			.owl-nav {
				display: none;
			}
		}
		&.background-pictures-1:after {
			content: '';
			background-image: url(#{$cdn-image-theme-path-base}templates/home/obogrevateli.png);
			background-repeat: no-repeat;
			background-size: 475px;
			width: 475px;
			height: 340px;
			position: absolute;
			top: -30px;
			right: 0;
		}
		&.background-pictures-2:after {
			content: '';
			background-image: url(#{$cdn-image-theme-path-base}templates/home/kondei.png);
			background-repeat: no-repeat;
			background-size: 475px;
			width: 475px;
			height: 340px;
			position: absolute;
			top: -30px;
			right: 0;
		}
	}
}
.widget_news {
	.row {
		margin: 0;
		& > div {
			padding-right: 0;
			padding-left: 0;
		}
	}
	&_item {
		border: 1px solid $lg;
		box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		background-color: $wh;
		margin: 5px 8px 5px 0;

		&_img {
			a {
				display: block;
				text-align: center;
			}
		}
		&_title {
			padding: 5px 0 10px 0;

			a {
				color: $d;
				font-size: 16px;

				&:hover {
					text-decoration: none;
				}
			}
		}
		&_date {
			margin: 5px 0;
			color: $dg;

		}
		&_description {
			color: $dg;
		}
		&_readmore {
			border: 1px solid $lg;
			margin-top: 5px;
			padding-top: 10px;
			padding-bottom: 10px;
			color: $d;

			a {
				color: inherit;
				font-size: initial;

				&:hover {
					text-decoration: none;
				}
			}
		}
		&_title, &_date, &_description, &_readmore {
			padding-left: 24px;
			padding-right: 24px;
		}
	}
	.show_all {
		margin: 24px 0 0;
		a {
			@include size(175px, 40px);
			background: linear-gradient(172.51deg, #4CAF50 12.84%, #4DB45E 12.85%, #26A6D1 94.74%);
			border-radius: 59px;
			color: $wh;
			&:hover, &:active {
				text-decoration: none;
				background: $tc;
			}
		}
	}
}
#news_list {
	.widget_news_item {
		width: calc(100% / 4 - 10px);
		margin: 5px;
		flex-direction: column;
		padding-bottom: 20px;

		&_title {
			line-height: 18px;
			margin-top: 0;
		}
		&_img {
			img {
				max-width: 100%;
			}
		}
	}
}
#news_item {
	.date {
		font-size: 18px;
		margin: 5px 0;
		color: $dg;
	}
}
.news_column {
	.allnews {
		display: block;
		font-weight: 400;
		padding-bottom: 10px;
		border-bottom: 1px solid #eee;
		text-decoration: underline;
	}
	.news_item {
		margin-bottom: 30px;

		.h4 {
			a {
				font-weight: 100;
				color: inherit;
			}
		}
	}
}