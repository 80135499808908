.alert-danger {
	color: $r;
	font-size: 16px;
	line-height: 24px;
}
.installmentAlertContainer {
	.alert {
		padding: 10px 15px;
	}
}
.flex {
	display: -webkit-box;
	display: -moz-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.flex-wrap {
	display: -webkit-box;
	display: -moz-box;
	display: -webkit-flex;
	display: flex;
	display: -ms-flexbox;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.flex-x-center {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.flex-x-sa {
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.flex-x-sb {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.flex-y-center {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.flex-xy-center {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.flex-x-end {
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.flex-y-end {
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.flex-x-srt {
	justify-content: flex-start;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
}

.svg-bl {
	fill: $bl;
}

.svg-tc {
	fill: $tc;
}

.svg-tc2 {
	fill: $tc2;
}

.svg-d {
	fill: $d;
}

.svg-wh {
	fill: $wh;
}

.svg-dg {
	fill: $dg;
}

.svg-red {
	fill: red;
}

.svg-red-c {
	fill: $rc;
}

.svg-blue-c {
	fill: $blc;
}

.svg-or-c {
	fill: $orc
}

.bg-lbg {
	background: $lbg;
}

.svg-icon-14 {
	@include size(14px, 14px);
}

.svg-icon-16 {
	@include size(16px, 16px);
}

.svg-icon-24 {
	@include size(24px, 24px);
}

.svg-icon {
	@include size(30px, 30px);
}

.svg-icon-32 {
	@include size(32px, 32px);
}

.svg-icon-40 {
	@include size(40px, 40px);
}

.svg-icon-60 {
	@include size(60px, 60px);
}

.rotate-90 {
	transform: rotate(90deg);
}

button {
	&:active, &:focus {
		outline: none !important;
	}
}

.btn-icon {
	background-color: $wh;
	border: none;
}

.widget-products {
	.tab-header {
		padding: 32px 0;
		width: fit-content;
		width: max-content;
		h2 {
			width: fit-content;
			text-transform: uppercase;
		}
		&:after {
			content: '';
			display: block;
			background: linear-gradient(178.74deg, #4CAF50 12.84%, #4DB45E 12.85%, #26A6D1 94.74%);
			border-radius: 36px;
			height: 8px;
			width: 100%;
		}
	}
	.owl-dots {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 18px;
		margin-top: 24px;
		.owl-dot {
			@include size(12px, 12px);
			border-radius: 50%;
			background-color: $lbl;
			margin: 0 5px;
			&.active {
				position: relative;
				background-color: $bl;
				&:after {
					content: '';
					background-color: $wh;
					@include size(16px, 16px);
					border-radius: 50%;
					display: block;
					z-index: -1;
					position: absolute;
					top: -2px;
					left: -2px;
				}
				&:before {
					content: '';
					background-color: $bl;
					@include size(18px, 18px);
					border-radius: 50%;
					display: block;
					z-index: -2;
					position: absolute;
					top: -3px;
					left: -3px;
				}
			}
		}
	}
}

.product-block {
	margin: 5px 8px;
	border: 1px solid $lg;
	box-sizing: border-box;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	background-color: $wh;
	.block-img {
		text-align: center;
		.image {
			position: relative;
			overflow: hidden;
			-webkit-transition: all 0.4s ease 0s;
			-o-transition: all 0.4s ease 0s;
			transition: all 0.4s ease 0s;
			padding: 10px 10px 0 10px;
			.img {
				.img-responsive {
					max-width: 100%;
					margin: auto;
				}
			}
		}
	}
	.product-meta {
		.top {
			padding: 0 24px;
			position: relative;
			.rating {
				height: 22px;
			}
			.name {
				min-height: 60px;
				a {
					color: $d;
				}
			}
			.price {
				.price-new {
					display: block;
					font-weight: 500;
					font-size: 18px;
					line-height: 24px;
					margin-bottom: 16px;
				}
				.special {
					width: 100%;
					height: 24px;
					.price-old {
						font-weight: 300;
						font-size: 14px;
						line-height: 20px;
						text-decoration-line: line-through;
						color: $dg;
					}
					.profit {
						font-weight: normal;
						font-size: 12px;
						line-height: 16px;
						color: $tc;
						background: $lbg;
						padding: 4px 8px;
						border-radius: 18px;
					}
				}
			}
		}
		.bottom {
			height: 48px;
			border-top: 1px solid $lg;
			padding: 0 16px;
			.bottom-left {
				padding: 0 6px;
				height: 100%;
				.quickview, .compare {
					padding: 0 2px;
					@include size(32px, 100%);
					button {
						padding: 0;
						svg {
							path {
								fill: $dg;
							}
						}
					}
				}
			}
			.cart {
				height: 100%;
				.in-cart {
					padding-left: 5px;
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					color: $dg;
					&:hover {
						color: $tc;
					}
				}
			}
		}
	}
	.product-sticker {
		position: absolute;
		color: #169FDD;
		bottom: 0;
		left: 24px;
		text-align: left;
		font-family: 'Rubik', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 32px;
		line-height: 14px;
		div {
			min-height: 20px;
		}
		.text-top {
			font-size: 18px;
			font-weight: 500;
			text-transform: uppercase;
		}
		.text-val {
			font-size: 32px;
			font-weight: bold;
			.prefix {
				font-size: 16px;
			}
		}
		.text-val.small-size {
			font-size: 21px;
		}
		.text-bot {
			font-size: 14px;
			font-weight: normal;
		}
	}
}

.page-home {
	.owl-item {
		& .active {
			&:first-child {
				.product-block {
					margin-left: 0;
				}
			}
		}
		.product-block {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}

.stickers {
	min-height: 24px;

	.product-label {
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		border-radius: 12px;
		padding: 3px 8px;
		@include size(fit-content,24px);
		margin-right: 8px;
		line-height: 24px;

		&.new {
			color: $bl;
			border: 1px solid $lbl;
		}
		&.sale {
			color: $tc;
			border: 1px solid $lgr;
		}
		.product-label-special, .product-label-new {
			margin-left: 4px;
		}
	}
}

.page-category, .page-product-search, .page-product-special {
	.product-grid {
		.description, .price-display-list, .qwt-compare-list {
			display: none;
		}
	}
}

.page-category, .page-product-search, .page-product-special {
	.product-list {
		.product-block {
			display: flex;
			flex-wrap: wrap;
			.block-img {
				width: 30%;
				.product-sticker {
					bottom: 23px;
				}
				.stickers {
					padding-top: 14px;
					padding-left: 14px;
				}
			}
			.product-meta {
				width: 70%;
				display: flex;
				flex-wrap: wrap;
				.name {
					margin-top: 24px;
					font-size: 18px;
					height: 48px;
					margin-bottom: 8px;
				}
				.rating {
					display: none;
				}
				.description {
					line-height: 20px;
					min-height: 100px;
				}
				.top {
					width: 70%;
					.price {
						display: none;
					}
					&:after {
						content: '';
						width: 1px;
						position: absolute;
						right: 0;
						top: 17px;
						bottom: 16px;
						background-color: $lbg2;
					}
					.qwt-compare-list {
						display: flex;
						flex-wrap: nowrap;
						padding-top: 12px;
						padding-bottom: 24px;
					}
				}
				.bottom {
					width: 30%;
					height: inherit;
					display: flex;
					flex-wrap: wrap;
					align-items: flex-end;
					.price-display-list {
						width: 100%;
						padding-top: 24px;
						padding-left: 8px;
						align-self: flex-start;
						.price {
							.price-new {
								display: block;
								font-weight: bold;
								font-size: 24px;
								line-height: 32px;
							}
							.special {
								width: 100%;
								height: 24px;
								.price-old {
									font-weight: 300;
									font-size: 14px;
									line-height: 20px;
									text-decoration-line: line-through;
									color: $dg;
									margin-right: 5px;
								}
								.profit {
									font-weight: normal;
									font-size: 12px;
									line-height: 16px;
									color: $tc;
									background: $lbg;
									padding: 4px 8px;
									border-radius: 18px;
								}
							}
						}
					}
					.bottom-left {
						display: none;
					}
					.cart {
						height: auto;
						padding-bottom: 24px;
					}
				}
			}
		}
	}
}

.page-checkout-buy {
	background: $lbg;
	h1 {
		padding-bottom: 50px;
		margin: 0 -15px;
	}
	.container-inside {
		& > div.row {
			margin: 0;
			.col-main {
				& > div.row {
					margin: 0;
				}
			}
		}
	}
	.sub-title, .total-text, #coupon_form h4, #is_ip p {
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
	}
	#buy_form_container {
		font-family: $hf;
		font-style: normal;
		color: $d;
		.row {
			display: flex;
			display: -webkit-box;
			display: -ms-flexbox;
			flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			flex-direction: row;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-ms-flex-direction: row;
			align-items: stretch;
			-webkit-box-align: stretch;
			-ms-flex-align: stretch;
			box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
			border-radius: 10px;
			.col-md-7, .col-md-5 {
				padding-left: 0;
				padding-right: 0;
			}
			.col-md-7 {
				background: $wh;
				border-radius: 10px 0 0 10px;
				border-right: 1px solid $lbg2;
				.shopping-cart-table {
					display: block;
					background: $wh;
					padding: 32px 32px 0 24px;
					margin-bottom: 0;
					border-radius: 10px 0 0 0;
					tr {
						display: flex;
						flex-wrap: wrap;
						td {
							display: block;
							border: 0;
							width: 100%;
						}
					}
					.product-item {
						display: flex;
						width: 100%;
						padding-bottom: 12px;
						margin-bottom: 12px;
						position: relative;
						border-bottom: 1px solid $lbg2;

						&:after {
							content: '';
							display: block;
							clear: both;
						}
						&:last-child {
							padding-bottom: 20px;
							border: 0;
						}
						.column-image {
							flex: 0 0 145px;
							float: left;
							padding: 0 16px 15px 0;
							box-sizing: content-box;

							img {
								border: 0;
								max-width: 100%
							}
						}
						.column-center {
							width: 100%;
						}
						.column-name {
							font-weight: 500;
							font-size: 18px;
							line-height: 24px;
							padding-bottom: 26px;
							width: calc(100% - 25px);
							a {
								color: $d;
								&:hover {
									text-decoration: none;
								}
							}
							&.product-deleted {
								font-size: inherit;
								line-height: inherit;
							}
						}
						.qty-price {
							display: flex;
							flex-wrap: nowrap;
							justify-content: space-between;
							align-items: center;
							& > div {
								flex-basis: 40%;
								text-align: left;
							}
							.column-price, .column-total {
								font-size: 18px;
								white-space: nowrap;
							}
							.column-total {
								padding-left: 16px;
							}
							.column-price {
								font-weight: 500;
								display: flex;
								flex-direction: column;
								.old {
									font-style: normal;
									font-weight: normal;
									font-size: 16px;
									text-decoration-line: line-through;
									-webkit-text-decoration-line: line-through;
									color: $dg;
									line-height: 24px;
								}
							}
							.column-quantity {
								flex-basis: 20%;
								border-radius: 3px;
								.input-group {
									&.btn-block {
										padding: 0 5px;
										background: $lbg;
									}
								}
								input {
									font-weight: 500;
									font-size: 18px;
									line-height: 24px;
									color: $d;
									border: 0;
									background: inherit;
									font-family: 'Rubik', sans-serif;
								}
								.add-up {
									transform: rotate(-90deg);
								}
								.add-down {
									transform: rotate(90deg);
								}
							}

						}
						.remove-item {
							position: absolute;
							width: 24px;
							height: 24px;
							top: 0;
							right: 0;
							&:hover {
								cursor: pointer;
							}
						}
						.attention {
							color: $r;
							margin-bottom: 5px;
						}
						svg {
							@include size(20px,20px);
							margin-right: 5px;
						}
						.btn-restore {
							display: flex;
							flex-wrap: nowrap;
							margin-top: 8px;
						}
					}
				}
			}
			.col-md-5 {
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
				align-items: flex-start;
				background: $wh;
				border-radius: 0 10px 10px 0;
				.container-total-item {
					margin: auto;
					margin-top: 0;
					padding: 32px 0 65px 0;
					width: 272px;
					.title {
						font-weight: 500;
						font-size: 24px;
						line-height: 32px;
						color: $d;
						padding-bottom: 24px;
					}
					.total-item {
						display: flex;
						flex-wrap: nowrap;
						flex-direction: row;
						justify-content: space-between;
						padding-bottom: 16px;
						&:nth-last-child(2) {
							margin-top: 8px;
							position: relative;
							&:before {
								content: "";
								position: absolute;
								top: -8px;
								width: 100%;
								height: 2px;
								background: $tc;
							}
							.sub-title, .total-text {
								font-size: 18px;
								font-weight: 500;
							}
						}
						.total-count-item {
							display: block;
							color: $dg;
							font-size: 12px;
							line-height: 16px;
							letter-spacing: 0.05em;
						}

					}
					#coupon_form {
						h4 {
							color: $dg;
						}
						#input-coupon {
							width: 100%;
							text-align: center;
						}
						#button-coupon {
							width: 100%;
							color: $tc;
							background-color: $wh;
							border-color: $tc;
							margin-top: 10px;
							&:hover {
								color: $wh;
								background-color: $tc;
							}
						}
						.alert {
							padding: 10px;
						}
					}
				}
			}
		}
	}

	#checkout-form {
		color: $d;
		padding-bottom: 40px;
		#personal-info, #delivery-info, #payment-info, #comment-bot {
			background: $wh;
			box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
			border-radius: 10px;
			padding: 32px 32px 40px 32px;
			margin-bottom: 48px;
		}
		.sub-title {
			font-weight: 500;
			font-size: 24px;
			line-height: 32px;
			padding-bottom: 24px;
			margin: 0;
		}
		#personal-info, #payment-info, #comment-bot, #agree-info {
			width: 58%;
		}
		#personal-info, #delivery-info, #payment_methods, #agree-info {
			label {
				display: -ms-flexbox;
				display: flex;
				-ms-flex-align: center;
				align-items: center;
				-ms-flex: auto;
				flex: auto;
				margin-bottom: 16px;
				cursor: pointer;
				input {
					&:checked {
						~ {
							span {
								border-color: $tc;
								position: relative;
								display: -ms-flexbox;
								display: flex;
								-ms-flex-align: center;
								align-items: center;
								-ms-flex-pack: center;
								justify-content: center;
								&:before {
									width: 10px;
									height: 10px;
									content: '';
									position: absolute;
									background: $tc;
									border-radius: 50%;
								}
							}
						}
					}
				}
				span {
					flex: 0 0 20px;
					height: 20px;
					border: 2px solid #EEEEEE;
					border-top-color: rgb(238, 238, 238);
					border-right-color: rgb(238, 238, 238);
					border-bottom-color: rgb(238, 238, 238);
					border-left-color: rgb(238, 238, 238);
					border-radius: 50%;
					box-sizing: border-box;
					margin-right: 10px;
				}
			}
		}
		#personal-info {
			#is_ip {
				margin-bottom: 24px;
				.radio_custom {
					display: inline-flex;
					flex-wrap: wrap;
					-ms-flex-wrap: wrap;
					align-items: end;
					width: 100%;
					label {
						width: 200px;
					}
					p {
						color: $d;
						margin: 0;
					}
				}
			}
			.required {
				label {
					&:before {
						content: '';
					}
				}
			}
		}
		#delivery-info {
			padding: 32px 0 0 0;
			.sub-title {
				padding: 0 0 24px 32px;
			}
			#shipping_methods {
				display: flex;
				flex-wrap: wrap;
				.radio_custom {
					width: calc(100% / 3);
					padding: 24px 25px 40px 25px;
					border: 1px solid $lbg2;
					border-right: 0;
					border-bottom: 0;
					margin: 0;
					color: $d;
					.sub-sub-title {
						font-size: 18px;
						line-height: 24px;
						padding-bottom: 16px;
					}
					.method-description {
						line-height: 20px;
						padding-bottom: 16px;
						min-height: 150px;
					}
					&:first-child {
						border-left: 0;
					}
					&.active {
						background: $lbg2;
					}
				}
			}
		}
		#agree-info {
			label {
				input {
					&:checked {
						~ {
							span {
								&:before {
									content: '\2714';
									background: transparent;
									border-radius: 0;
									top: -6px;
									color: $tc;
									font-size: 18px;
								}
							}
						}
					}
				}
				span {
					border-radius: 3px;
				}
			}
			#button-order {
				margin-top: 16px;
				width: 272px;
				height: 40px;
				padding: 0;
			}
		}
	}
	label {
		margin: 0 0 16px 0;
		padding: 0;
		p {
			margin: 0;
			font-size: 16px;
			line-height: 24px;
			color: $d;
			font-weight: normal;
			a {
				color: inherit;
				b {
					font-weight: normal;
				}
			}
		}
	}
	.firstname, .telephone, .email, .address, .comment, .city, .payment-company, .payment-inn {
		label {
			font-size: 14px;
			line-height: 20px;
			font-style: normal;
			font-weight: normal;
			margin-bottom: 4px !important;
		}
	}
}

.sphinx-viewall {
	#view-all {
		width: 70%;
		font-weight: normal;
		text-align: center;
		margin: 15px 0;
		height: auto;
		color: $wh;
		background-color: $tc;
		border-color: $tc;
		line-height: 36px;
		padding: 0 30px;
		-webkit-transition: all 200ms ease 0.2s;
		-o-transition: all 200ms ease 0.2s;
		transition: all 200ms ease 0.2s;
		-webkit-border-radius: 59px;
		-moz-border-radius: 59px;
		-ms-border-radius: 59px;
		-o-border-radius: 59px;
		border-radius: 59px;
		&:hover {
			background-color: $lgr2;
			border-color: $lgr2;
		}
	}
}

.wrapper {
	.wrapper_empty {
		padding: 10px 0 40px 0;
	}
}

.owl-item {
	.description, .qwt-compare-list, .price-display-list {
		display: none;
	}
}

.page-product {
	#product {
		.price-quantity {
			justify-content: space-between;
		}
	}
}

#payment {
	.no-cash {
		padding: 0;
		border-top: none;
		border-bottom: none;
		margin: 70px 0 0;
		.no-cash_left {
			padding: 0 0 0 15px;
		}
		.no-cash_right {
			padding: 0;
			.payment_grid {
				display: -webkit-box;
				-webkit-flex-wrap: wrap;
				display: flex;
				height: 100%;
				overflow: hidden;
				font-size: 12px;
				.gr {
					width: 20%;
					padding: 10px;
					border-left: none;
					position: relative;
					background: #fff;
					top: 0;
					display: block !important;
					&:first-child {
						border-left: none !important;
					}
					.gr-inner {
						border: 1px solid #b9b8b8;
						padding: 30px;
						text-align: center;
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
						height: 100%;
						align-content: baseline;
						img {
							margin: 0 0 10px 0;
							max-width: 65px;
							align-self: center;
						}
						p {
							margin: 0;
							clear: both;
							font-size: 16px;
							line-height: 20px;
							text-align: center;
							color: #3d3d3d;
						}
					}
				}
				#feature1 {
					z-index: 50;
					left: -20%;
				}
				#feature2 {
					z-index: 40;
					left: -40%;
				}
				#feature3 {
					z-index: 30;
					left: -60%;
				}
				#feature4 {
					z-index: 20;
					left: -80%;
				}
				#feature5 {
					z-index: 20;
					left: -100%;
				}
			}
		}
	}
	p {
		font-size: 18px
	}
	h2 {
		color: #4f4e4d;
		text-transform: none;
	}
	.bank-card_lef, .yandex-money_left {
		padding: 0;
		padding-left: 15px;
	}
	.bank-card_right {
		padding: 0;
		padding-right: 70px;
		padding-left: 70px;
	}
	.bank-card_right__ul {
		padding-left: 0;
		margin-top: 20px;
		margin-bottom: 30px;
		font-size: 16px;
		li {
			list-style-type: none;
			margin: .5em 0;
			padding: 0 0 0 40px;
			&:nth-child(1) {
				background: url("/image/payment/card-list-1.png") no-repeat 0 .1em;
				padding-bottom: 10px;
			}
			&:nth-child(2) {
				background: url("/image/payment/card-list-2.png") no-repeat 0 .1em;
				padding-bottom: 10px;
			}
			&:nth-child(3) {
				background: url("/image/payment/card-list-3.png") no-repeat 0 .1em;
				padding-bottom: 10px;
			}
			&:nth-child(4) {
				background: url("/image/payment/card-list-4.png") no-repeat 0 .1em;
				padding-bottom: 5px;
			}
		}
	}
	.terminals {
		background: url("/image/payment/terminal-background.png") no-repeat 0 10em;
		background-size: 100%;
		margin: 0;
		.terminals-1_left, .terminals-3_left, .terminals-4_right, .terminals-5_left {
			padding: 0;
			padding-left: 30px
		}
		.terminals-1_left__description {
			padding-left: 60px;
			padding-top: 25px;
		}
		.terminals-1_right, .terminals-2_left, .terminals-3_right, .terminals-4_left, .terminals-5_right {
			padding: 0;
		}
		.terminals-1_right {
			.terminals-1_right__image {
				text-align: right
			}
		}
		.terminals-1_right .terminals-1_right__image img,
		.terminals-2_left .terminals-2_left__image img,
		.terminals-3_right .terminals-3_right__image img,
		.terminals-5_right .terminals-5_right__image img {
			display: inline-block;
		}
		.terminals-2 {
			padding-top: 60px;
		}
		.terminals-2_right {
			padding: 0;
			padding-right: 70px;
			padding-top: 100px;
		}
		.terminals-2_right__header {
			padding-left: 45px
		}
		.terminals-2_right__header, .terminals-3_left__header, .terminals-4_right__header, .terminals-5_left__header {
			font-size: 16px;
			font-weight: 700;
		}
		.terminals-2_right__description {
			padding-left: 45px;
			padding-top: 10px;
		}
		.terminals-2_right__description, .terminals-3_left__description, .terminals-4_right__description, .terminals-5_left__description {
			font-size: 16px;
		}
		.terminals-3_left {
			padding-right: 40px;
			padding-top: 150px;
		}
		.terminals-3_left__header {
			padding-left: 65px
		}
		.terminals-3_left__description {
			padding-left: 65px;
			padding-top: 10px;
		}
		.terminals-3_right {
			.terminals-3_right__image {
				border-top: 300px solid transparent;
				border-right: 600px solid #5bad3f;
				border-bottom: 300px solid transparent;
				img {
					position: absolute;
					z-index: 10;
					top: 50%;
					transform: translateY(-50%);
					right: 200px;
					height: 80%;
					width: auto;
				}
			}
		}
		.terminals-4_left {
			padding-left: 35px;
		}
		.terminals-4_right {
			padding-right: 40px;
			padding-top: 90px
		}
		.terminals-4_right__header {
			padding-left: 115px
		}
		.terminals-4_right__description {
			padding-left: 115px;
			padding-top: 10px;
		}
		.terminals-5 {
			padding-top: 40px;
			padding-bottom: 40px;
		}
		.terminals-5_left {
			padding-right: 140px;
			padding-top: 210px;
		}
		.terminals-5_left__header {
			padding-left: 65px
		}
		.terminals-5_left__description {
			padding-left: 65px;
			padding-top: 10px;
		}
		.terminals-2_left .terminals-2_left__image, .terminals-3_right .terminals-3_right__image, .terminals-5_right .terminals-5_right__image {
			text-align: center;
		}
	}
	.yandex-money {
		margin: 0;
		border-top: 1px solid #ededed;
		border-bottom: 1px solid #ededed;
		padding: 0 0 50px;
		.yandex-money_right__logo {
			text-align: center;
			padding-top: 90px;
		}
		.yandex-money_left__mockup {
			text-align: right;
			padding-top: 30px;
		}
		.yandex-money_right__blueBackground {
			padding: 34px 85px 35px 90px;
			color: #4f4e4d;
			background-color: #add69f;
			font-size: 16px;
			line-height: 1.5em;
			margin-top: 17%;
			.gilroy-h {
				font-weight: 700;
			}
		}
		.yandex-money_left__lastRow {
			padding-right: 35px;
			padding-top: 100px;
		}
		.yandex-money_right__screen {
			padding-right: 10px;
		}
		.yandex-money_right__warning {
			margin-left: 0;
			margin-right: 0;
			display: flex;
			align-items: center;
			padding-left: 12%;
			padding-top: 30px;
		}
		.yandex-money_right__warning__icon {
			border-radius: 50px;
			background-color: #5bad3f;
			padding: 7px 17px;
			color: #fff;
			margin-right: 30px;
			font-size: 20px;
		}
	}
	.yandex-money_right {
		padding: 0;
	}
	.yandex-money_right__logo img, .yandex-money_left__mockup img {
		display: inline-block;
	}
	.webmoney {
		margin: 0;
		border-bottom: 1px solid #ededed;
		padding: 0 0 50px;
	}
	.webmoney_left {
		padding: 0 0 0 15px;
	}
	.webmoney_left__ul {
		padding-left: 50px;
		margin-top: 250px;
		margin-bottom: 65px;
		font-size: 14px;
		li {
			list-style-type: none;
			margin: .5em 0;
			padding: 0 0 0 40px;
			min-height: 27px;
			&:nth-child(1) {
				background: url("/image/payment/card-list-1.png") no-repeat 0 .1em;
				padding-bottom: 10px;
			}
			&:nth-child(2) {
				background: url("/image/payment/card-list-2.png") no-repeat 0 .1em;
				padding-bottom: 10px;
			}
			&:nth-child(3) {
				background: url("/image/payment/card-list-3.png") no-repeat 0 .1em;
				padding-bottom: 10px;
			}
			&:nth-child(4) {
				background: url("/image/payment/card-list-4.png") no-repeat 0 .1em;
				padding-bottom: 5px;
			}
		}
	}
	.webmoney_right {
		padding: 50px 70px 0 70px;
	}
	.credit {
		.credit_center {
			padding: 0 15px;
		}
		.credit_center-wrap {
			max-width: 800px;
			margin: 0 auto;
			padding: 0 20px;
			h3 {
				text-align: center;
			}
		}
	}
}

#category-slider {
	padding-bottom: 32px;
	.owl-nav {
		.owl-prev, .owl-next {
			position: absolute;
			top: 0;
			width: 36px;
			border-radius: 100%;
			border: 2px solid #f7f8f8;
			height: 36px;
			svg {
				width: 25px;
			}
		}
		.owl-prev {
			right: -60px;
			svg {
				-webkit-transform: rotate(180deg);
				-ms-transform: rotate(180deg);
				transform: rotate(180deg);
				margin: 4px 0 0 5px;
			}
		}
		.owl-next {
			right: -100px;
			svg {
				margin: 4px 0 0 2px;
			}
		}
	}
}

#html-on-main-page {
	.text-block {
		width: 50%;
		.text-block-el {
			padding: 0 10px 32px 0;
			.text-block-svg {
				float: left;
				min-height: 100px;
			}
		}
	}
	.img-block {
		width: 50%;
		position: relative;
		a {
			position: absolute;
			bottom: 20px;
			left: 20px;
			color: #fff;
			font-size: 18px;
			width: 93%;
			svg {
				float: right;
			}
		}
		a:hover {
			text-decoration: none;
		}
	}
}

.advantages-home {
	display: flex;
	justify-content: center;

	.item {
		width: calc(100% / 4);
		margin-right: 16px;

		&:last-child {
			margin-right: 0;
		}
		svg {
			width: 80px;
			height: 80px;
		}
		.title, .description {
			line-height: 24px;
		}
		.title {
			font-size: 18px;
			font-weight: 500;
			margin-bottom: 8px;
			margin-top: 0;
		}
		.description {
			font-size: 16px;
		}
		ul {
			padding-left: 20px;
		}
	}
}

.pts-newsletter {
	padding: 65px 0 120px 0;
	.newsletter-inner {
		border-radius: 0 10px 10px 0;
		border: 1px solid #eee;
		border-left: 0;
		padding: 0;
		.img-block {
			img {
				border-radius: 10px 0 0 10px;
				border-left: 1px solid #eee;
			}
		}
		.content-block {
			padding: 20px;
			.content-block-inner {
				margin: 0 auto;
				max-width: 400px;
				h2 {
					margin-bottom: 20px
				}
				#nl_email {
					height: 50px;
				}
				#nl_submit {
					width: 100%;
					text-transform: none;
					font-size: 16px;
				}
				.checkbox_custom {
					label {
						padding: 10px 5px;
					}
					#nl_agree {
						display: none
					}
				}
			}
		}
	}
}

.page-information-contact {
	.contact-icon {
		display: inline-block;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background: $tc;
		top: 0;
		position: absolute;
		i.fa {
			color: #FFFFFF;
			font-size: 30px;
			padding: 11px 10px 10px 10px;
			width: 50px;
			height: 50px;
			text-align: center;
			margin-top: -1px;
		}
	}
	.contact-text {
		display: inline-block;
		padding-left: 60px;
		min-height: 60px;
		margin-bottom: 10px;
		line-height: 24px;
		.contact-text-b-days {
			div {
				display: inline-block;
			}
		}
	}
}

.page-information-direktoru {
	.type-mail-send {
		list-style: none;
		padding: 0;
		.radio_custom {
			.input-control {
				cursor: pointer;
				display: inline-block;
				position: relative;
				font-weight: 500;
				margin: 1px 0;
				font-size: 18px;
				line-height: 27px;
				text-transform: none;
				padding-left: 30px;
				padding-top: 0;
				color: inherit;
				input {
					position: absolute;
					width: auto !important;
					z-index: 1;
					opacity: 0
				}
				input:checked ~ {
					.input-control__indicator {
						&:after {
							opacity: 1;
							filter: alpha(opacity=100);
						}
					}
				}
				.input-control__indicator {
					background-color: #fff;
					border: 2px solid $tc;
					width: 20px;
					height: 20px;
					top: 2px;
					border-radius: 50%;
					display: block;
					position: absolute;
					left: 0;
					user-select: none;
					text-align: center;
					&:after {
						border-radius: 50%;
						content: "";
						display: block;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						transform: scale(0.8);
						transform-origin: center center;
						width: 100%;
						background: $tc;
						opacity: 0;
						filter: alpha(opacity=0);
						-webkit-transition: 0.1s opacity linear;
						-o-transition: 0.1s opacity linear;
						transition: 0.1s opacity linear;
						border: 2px solid $tc;
					}
				}

			}
		}
	}
}

.page-information, .page-information-payment {
	#content {
		padding-bottom: 80px;
	}
}

#mfilter-top {
	.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
		background: $wh;
	}
	.mfilter-btn-box {
		padding-bottom: 24px;
	}
	.mfilter-button {
		border-top: 0;
		padding: 32px 0 24px 0;
		font-size: initial;
		clear: initial;
		overflow: initial;
		display: none;
		a {
			margin-right: 16px;
		}
	}
	.mfilter-content {
		border: 0;
		padding-bottom: 20px;
		&-opts {
			max-width: 440px;
			.mfilter-slider-slider, #mfilter-price-slider {
				.ui-slider-range {
					background: $tc !important;
				}
			}
		}
		.mfilter-heading {
			.mfilter-head-icon {
				display: none;
			}
		}
		.mfilter-opts-container {
			border: 0;
			.mfilter-tb-as-tr {
				display: inline-block;
				min-width: 58px;
				height: 40px;
				border-radius: 43px;
				box-shadow: 0 0 4px rgba(247, 248, 248, 0.8);
				border: 2px solid $lbg;
				background: $wh;
				margin-right: 16px;
				margin-bottom: 16px;
				position: relative;
				.mfilter-col-input {
					display: none;
				}
				label {
					&.mfilter-tb-as-td {
						border-top: 0;
						display: block;
						margin: 6px 15px;
						text-align: center;
						padding: 0;
						cursor: pointer;
						font-style: normal;
						font-weight: normal;
						font-size: 16px;
						line-height: 24px;
						color: $dg;
					}
				}
				.mfilter-col-count {
					.mfilter-close {
						display: block;
						width: 52px;
						height: 30px;
						margin: auto;
						padding: 0;
						background: transparent;
						float: left;
					}
				}
				&.mfilter-input-active {
					background: #4caf50;
					label {
						color: $wh;
					}
					.mfilter-col-count {
						position: absolute;
						top: 0;
						left: 0;
						width: 52px;
						height: 36px;
						cursor: pointer;
						border: 0;
					}
				}
				&:hover {
					cursor: pointer;
				}
			}
		}
		.mfilter-heading {
			background: transparent;
			cursor: default;
			font-size: 18px;
			font-weight: 500;
			border: 0;
			font-family: $hf;
		}
	}
}

#cart-url {
	&:hover {
		.cart_pop_block {
			display: block;
		}
	}
}

.cart_pop_block {
	border: 1px solid #eee;
	display: none;
	background: #fff;
	padding: 0 16px 32px 16px;
	position: absolute;
	top: 60px;
	width: 460px;
	right: 0;
	cursor: auto;
	z-index: 99;
	.item {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		-ms-flex-pack: start;
		justify-content: flex-start;
		padding: 8px 0;
		border-bottom: 1px solid #BABABA;
		margin-bottom: 8px;
		position: relative;
		.image {
			width: 48px;
			margin-right: 16px;
			a {
				display: inline-block;
				position: relative;
				text-align: center;
				margin: auto;
				img {
					width: 100%;
				}
			}
		}
		.name {
			width: 186px;
			padding-right: 10px;
			a {
				color: initial;
			}
		}
		.price {
			font-size: 18px;
			font-weight: bold;
			padding: 15px 0;
		}
		.remove {
			position: absolute;
			top: 30px;
			right: 20px;
			svg {
				width: 24px;
				height: 24px;
			}
		}
	}
	a.btn-primary {
		width: 100%;
	}
	.pop-total {
		text-align: right;
		font-size: 18px;
		font-weight: bold;
		p {
			width: 25%;
			display: inline-block;
		}
		.pop-coupon {
			p {
				&:first-child {
					width: 40%;
					font-size: 14px;
					font-weight: 500;
				}
			}
		}
	}
}

.modal-overlay, #reserve_form_one_click {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: $d;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 8050;
}

.recaptcha_container {
	.control-label {
		width: auto;
	}
}

.product-filter {
	padding-top: 25px;
	border-top: 1px solid $tc2;
	.selectric {
		width: 336px;
		border-radius: 20px;
		.label {
			text-align: left;
			font-size: 16px;
			line-height: 24px;
			padding: 3px 0;
		}
		.button {
			&:after {
				width: 14px;
				height: 14px;
				right: 15px;
				top: 5px;
			}
		}
	}
	.selectric-open {
		.selectric {
			.button {
				&:after {
					top: 18px;
					right: 13px;
				}
			}
		}
	}
}

.header-top {
	background: rgba(247, 248, 248, 0.5);
}

.page-product-compare {
	.tr-product-name {
		a {
			color: $d;
			strong {
				font-weight: 500;
			}
		}
	}
	thead {
		td {
			padding-left: 15px !important;
		}
	}
	.compare_actions {
		.btn {
			width: 150px;
		}
	}

}

.selectric-wrapper {
	position: relative;
	cursor: pointer;
}

.selectric-responsive {
	width: 100%;
}

.selectric {
	border: 1px solid $lg;
	border-radius: 0;
	background: $white;
	position: relative;
	overflow: hidden;
	.label {
		display: block;
		white-space: nowrap;
		overflow: visible;
		width: calc(100% - 35px);
		text-overflow: ellipsis;
		margin: 0 25px 0 10px;
		padding: 8px 0;
		font-size: 14px;
		color: $black;
		height: 30px;
		font-weight: 400;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		line-height: 1;
	}
	.button {
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		width: 38px;
		line-height: 38px;
		background-color: $white;
		color: $black;
		text-align: center;
		&:after {
			content: '';
			position: absolute;
			@include size(6px, 6px);
			border-bottom: 1px solid $black;
			border-right: 1px solid $black;
			@include transform(rotate(45deg));
			right: 10px;
			top: 10px;
		}
	}
}

.selectric-focus .selectric {
	border-color: #aaaaaa;
}

.selectric-hover .selectric {
	.button {
		color: #a2a2a2;
		&:after {
			border-top-color: #a2a2a2;
		}
	}
}

.selectric-open {
	z-index: 9999;
	.selectric .button::after {
		@include transform(rotate(225deg));
		transform-origin: 4px 5px;
	}
	.selectric-items {
		display: block;
	}
}

.selectric-disabled {
	filter: alpha(opacity=50);
	opacity: 0.5;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.selectric-hide-select {
	position: relative;
	overflow: hidden;
	width: 0;
	height: 0;
	select {
		position: absolute;
		left: -100%;
	}
	&.selectric-is-native {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 10;
		select {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 100%;
			width: 100%;
			border: none;
			z-index: 1;
			box-sizing: border-box;
			opacity: 0;
		}
	}
}

.selectric-input {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	outline: none !important;
	border: none !important;
	*font: 0/0 a !important;
	background: none !important;
}

.selectric-temp-show {
	position: absolute !important;
	visibility: hidden !important;
	display: block !important;
}

.selectric-items {
	display: none;
	position: absolute;
	top: 31px;
	right: 0;
	background: $wh;
	border: 1px solid $lg;
	z-index: -1;
	box-shadow: 0 0 10px -6px;
	.selectric-scroll {
		height: 100%;
		overflow: auto;
	}
	ul, .selectric-items li {
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 14px;
		line-height: 20px;
		min-height: 20px;
	}
	li {
		display: block;
		padding: 10px;
		color: $black;
		cursor: pointer;
		line-height: 16px;
		white-space: nowrap;
		&.selected, &.highlighted, &:hover {
			color: $wh;
			background-color: $tc;
		}
	}
	.disabled {
		filter: alpha(opacity=50);
		opacity: 0.5;
		cursor: default !important;
		background: none !important;
		color: #666 !important;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.selectric-group {
		.selectric-group-label {
			font-weight: bold;
			padding-left: 10px;
			cursor: default;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			background: none;
			color: #444;
		}
		&.disabled li {
			filter: alpha(opacity=100);
			opacity: 1;
		}
	}
}

.selectric-above .selectric-items {
	top: auto;
	bottom: 100%;
}

.logo-store, #powered .footer-name {
	svg {
		path {
			&:nth-child(1) {
				fill: #636466;
				fill-rule: evenodd;
			}
			&:nth-child(2) {
				fill-rule: evenodd;
				fill: #636466;
			}
			&:nth-child(3) {
				fill-rule: evenodd;
				fill: #5bba47;
			}
		}
	}
}

//header
#mainmenutop {
	.nav.navbar {
		height: 60px;
	}
	.panel-v4 .panel-body {
		border: none !important;
	}
}

.product-markdown-view {
	.block-act {
		padding-right: 16px;

		.price-quantity {
			.quantity {
				padding: 0;

				.plus-minus {
					height: auto;
				}
			}
		}
		.cart {
			.btn {
				margin: 0;
			}
		}
		.button-link {
			margin: 0;
		}
	}
	.btn-grooup-ci, .btn-links  {
		gap: 8px;
	}

	.cheaper-products {
		flex-direction: column;
		gap: 8px;

		&.general {
			margin-bottom: 16px;

			.cheaper-items {
				.cheaper-item {
					border: 1px solid $dg;

					&__price {
						flex-direction: column;
						gap: 0;
					}
				}
				.cheaper-item-info {
					align-items: center;
					flex-direction: row;
				}
			}
		}
		&__title {
			color: $bk;
			font-size: 16px;
			line-height: 16px;
			font-weight: 500;
		}
		.cheaper-items {
			display: flex;
			flex-direction: column;
			gap: 16px;

			.cheaper-item {
				display: flex;
				flex-direction: column;
				gap: 16px;
				padding: 16px;
				border: 1px solid $lbg2;
				@include rounded-corners(10px);

				&:hover {
					text-decoration: none;
				}
				&__status, &__title {
					color: $mg;
				}
				&__price {
					flex-direction: row;
					gap: 16px;
					align-items: flex-end;
				}
				.special {
					height: auto;
				}
				.price {
					&-new, &-old {
						line-height: 1;
					}
					&-new {
						font-size: 28px;
					}
					&-old {
						margin: 0;
					}
				}
			}
			.cheaper-item-info {
				color: $bk;
				gap: 16px;
				align-items: flex-start;
				flex-direction: column;
			}
		}

	}
}