.#{$app-brand-prefix}-megamenu {
	.navbar {
		margin: 0;
		border: 0;
	}
	.navbar-collapse {
		padding: 0;
	}
	.navbar-nav {
		li {
			&:nth-child(1), &:nth-child(2), &:nth-child(3) {
				a {
					padding-right: 40px;
				}
			}
		}
	}
	.navbar-nav {
		> li {
			height: 50px;
			&.aligned-left {
				.dropdown-menu {
					left: -1px;
					right: auto;
				}
				.dropdown-submenu {
					.dropdown-menu {
						left: 100%;
					}
				}
			}
			> .dropdown-menu {
				top: 100%;
				@include opacity(0);
				@include transition(all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s);
				display: block;
				position: absolute;
				visibility: hidden;
				width: 384px !important;
				border-radius: 0 10px 10px 10px;
			}
			&:hover,
			&:focus {
				> .dropdown-menu {
					@include opacity(1);
					visibility: visible;
					display: block;
					top: 100%;
				}
			}
			> a {
				padding: 14px 16px 14px 0;
				font-size: $megamenu-link-font-size;
				font-weight: 500;
				font-family: $hf;
				color: $d;
				background-color: transparent !important;
				@include transition(all 200ms ease-out);
				line-height: 24px;
				&:hover, &:focus, &:active {
					text-decoration: underline #0aa948;
				}
			}
			&.full-width {
				position: static;
			}
		}
	}
	/* level 2 */
	.dropdown-menu {
		top: 100%;
		left: -9999px;
		height: auto;
		color: $dg;
		border: 1px $wh solid;
		min-width: $megamenu-sub-min-width;
		padding: 0;
		background-color: $megamenu-sub-bg-color;
		@include rounded-corners(0);
		@include transition(opacity 0.3s linear 0s);
		z-index: 9999;
		.cat_all {
			padding-bottom: 0;
			box-shadow: 4px 4px 8px rgba(99, 100, 102, 0.25);
			border-radius: 0 10px 10px 0;
			& > li {
				&:first-child {
					border-radius: 0 10px 0 0;
				}
				&:last-child {
					border-radius: 0 0 10px 10px;
				}
			}
			li.haschildren, li.notchildren {
				background: $wh;
				a {
					display: flex;
					flex-wrap: nowrap;
					img {
						height: 100%;
						margin: auto;
						margin-right: 8px;
					}
					.title {
						width: 100%;
					}
					&:after {
						position: absolute;
						content: '\f112';
						font-family: $fa;
						font-size: 22px;
						@include size(24px, 24px);
						right: 10px;
						top: 12px;
					}
				}
				&:first-child {
					a {
						&:after {
							top: 11px;
						}
					}
				}
			}
			li.notchildren {
				a {
					&:after {
						content: '';
					}
				}

			}
		}
		ul, ol {
			padding: 10px 0 !important;
		}
		li {
			padding: 0;
			list-style: none;
			line-height: 24px;
			background: $lbg;
			a {
				padding: 8px 16px;
				display: inline-block;
				width: 100%;
				color: $dg;
				font-size: $font-size-base + 4;
				font-weight: normal;
				position: relative;
				&:hover {
					background-color: transparent;
					text-decoration: none;
					color: $tc;
				}
			}
			&.haschildren {
				&:hover:after {
					background-position: center -17px;
				}
				&:first-child {
					&:after {
						top: 30px;
					}
				}
			}
			> ul.children {
				top: 5px;
				left: 285px;
				right: -250px;
				position: absolute;
				background: $wh;
				display: none;
				z-index: 99;
				height: 100%;
				box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
				> li {
					a {
						padding: 10px 40px 15px 30px;
						color: $bk;
					}
					&:last-child {
						border: none;
					}
				}
			}
			&:hover {
				> ul.children {
					left: 96%;
					display: block;
					top: 0;
					width: 384px;
					border-radius: 0 10px 10px 0;
					text-decoration: none;
					li {
						&:first-child {
							border-radius: 0 10px 0 0;
						}
						&:last-child {
							border-radius: 0 0 10px 0;
						}
					}
				}
			}
		}
	}
	.dropdown {
		&:hover {
			> .dropdown-menu {
				display: block;
				left: 0;
			}
			> a {
				.fa {
					color: $bk;
				}
			}
		}
	}
	.mega-group {
		& > .dropdown-toggle {
			border: 0;
			display: block;
			letter-spacing: 3px;
			text-transform: uppercase;
			font-family: $megamenu-heading-title;
			color: $megamenu-heading-color;
			.fa {
				color: $white;
			}
		}
	}
	.widget-heading {
		font-size: $font-size-base + 2;
		margin: 0 0 20px;
	}
	.widget-images {
		margin-bottom: 20px;
		padding-top: 5px;
	}
	.megamenu .cols1 {
		min-width: 200px;
	}
	.megamenu .cols2 {
		min-width: 500px;
	}
	.megamenu .cols3 {
		min-width: 740px;
	}
	.sidebar .product-block {
		padding: 5px 0;
		border-bottom: none;
		.image {
			padding: 0;
		}
	}
}

.ocwall {
	background: transparentize($d, .15);
	position: fixed;
	width: 100%;
	height: 100%;
	right: -70%;
	top: 0;
	opacity: 0;
	visibility: hidden;
	@include transition(opacity 0.2s ease-in-out);
	.menutoggle {
		position: fixed;
		@include size(50px, 50px);
		background: url('/image/i/closew.png') center center no-repeat;
		background-size: cover;
		top: calc(50% - 25px);
		left: calc(85vw - 25px);
	}
}

.row-offcanvas {
	.arrow-action {
		cursor: pointer;
		position: absolute;
		top: 24px;
		right: 5px;
		margin-top: -13px;
		width: 30px;
		z-index: 10;
		transform-origin: 11px 12px;
		transition: ease .2s;
		svg {
			width: 24px;
			height: 24px;
		}
	}
	.offcanvas-inner {
		border-radius: 0 10px 10px 0;
		font-family: 'Rubik', sans-serif;
		font-style: normal;
		list-style: none;
		width: 71%;
		.offcanvas-btn-close {
			position: absolute;
			right: 32%;
			top: 9px;
			button {
				padding: 0;
				background: transparent;
				width: 30px;
				height: 30px;
				span {
					&:before {
						color: $d;
						font-size: 30px;
					}
				}
			}
		}
	}
	.offcanvas-body {
		a {
			color: $d;
			&:hover {
				text-decoration: none;
			}
		}
		.panel-heading {
			height: 48px;
			display: flex;
			align-items: center;
			font-weight: 500;
			font-size: 18px;
			padding-left: 24px;
		}
		li.haschildren {
			position: relative;
		}
		ul.children {
			padding: 5px 40px 0 52px;
			margin: 0;
			& + .arrow-action {
				svg {
					transform: rotate(90deg);
				}
				&.collapsed {
					svg {
						transform: rotate(0deg);
					}
				}
			}
		}
		.mob_level_0 {
			& > .panel-title {
				padding-left: 24px;
				background: $lbg2;
				height: 48px;
				font-size: 16px;
				display: flex;
				align-items: center;
			}
		}
		.mob_level_1 {
			& > li {
				min-height: 48px;
				font-size: 16px;
				padding: 7px 0 7px 24px;
				border-bottom: 1px solid $lbg2;
				& > a {
					padding-right: 40px;
					display: flex;
					span {
						margin-left: 15px;
					}
					img {
						align-self: flex-start;
					}
				}
				&:last-child {
					border-bottom: none;
				}
			}
		}
		ul.mob_level_2 {
			padding: 5px 0 0 43px;
			font-size: 14px;
			li {
				border-bottom: 1px solid $lbg2;
				padding: 5px 0;
				& > .arrow-action {
					top: 19px;
				}
				&:last-child {
					border-bottom: none;
				}
			}
			a {
				display: block;
				margin-right: 40px;
			}
			.arrow-action {
				svg {
					width: 20px;
					height: 20px;
				}
			}
		}
		ul.mob_level_3 {
			padding-right: 0;
			li {
				border-bottom: 1px solid $lbg2;
				a {
					margin-right: 10px;
				}
				&:last-child {
					border-bottom: none;
				}
			}
		}
	}
}

#offcanvasmenu {
	ul {
		margin: 0;
		padding: 8px 0;
		background: $lbg2;
		li {
			font-size: 16px;
			line-height: 20px;
			font-weight: 500;
			padding: 8px 26px;
		}
	}
}

.row-offcanvas.active {
	.ocwall {
		opacity: 1;
		visibility: visible;
		z-index: 9999;
	}
	.sidebar-offcanvas {
		box-shadow: 4px 4px 8px $lbg2;
		border-radius: 0 10px 10px 0;
		overflow-y: auto;
		overflow-x: hidden;
	}

}