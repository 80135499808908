html {
	overflow-x: hidden;
}

/*
* CONTENT
 */
#pav-masshead {
	.inner {
		@include container-layout-variant($masshead-color, $masshead-bg, $masshead-color, lighten($masshead-color, 5%));
	}
}

.breadcrumbs {
	.container {
		background: transparent;
	}
}

/*
* NAVIGATION
*/
#pav-mainnav {
	.inner {
		background-color: $theme-color-secondary;
	}
}

#breadcrumb {
	padding-left: 0;
	padding-right: 0;
}

#footer {
	ul, ol {
		margin: 0;
	}
}

.footer-center {
	box-shadow: 4px -4px 8px rgba(99, 100, 102, 0.25);
	padding: 48px 0 25px;
	ul {
		li {
			margin: 0 0 8px;
			&:last-child {
				margin: 0;
			}
			span:first-child {
				margin: 0 0 8px;
			}
			a {
				color: $d;
				&:hover {
					color: $tc;
					cursor: pointer;
					text-decoration: none;
				}
			}
		}
	}
	h4 {
		margin: 0 0 16px;
	}
	.ft-phone, .ft-addr {
		& > span {
			width: 100%;
		}
	}
}

/* POWER BY */
#powered {
	background: $wh;
	padding: 24px 0 32px;
	border-top: 1px solid $lg2;
	.payment-method {
		img {
			margin-left: -10px;
		}
	}
	.footer-name {
		svg, .additional {
			max-width: 176px;
			height: auto;
		}
		.additional {
			display: block;
		}
	}
	.icon-block {
		max-width: 300px;
	}
}
.for-send-error-press {
	margin: 15px 0;
	font-style: italic;
}

#blackfriday {
	position: fixed;
	bottom: 0;
	width: 100%;
	background: $tc;
	z-index: 9999;
	text-align: center;
	color: #fff;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 25px;
	line-height: 50px;
	span.text {
		padding: 0 50px;
		display: inline-block;
	}
	.fa {
		position: absolute;
		right: 15px;
		top: 50%;
		margin-top: -12px;
		font-size: 25px;
		cursor: pointer;
	}
}

.page-themecontrol-product {
	#blackfriday {
		display: none;
	}
}

//mobile
@media (max-width: $screen-xs-max) {
	.footer-center {
		padding: 32px 0 0;
		.box {
			padding-bottom: 16px;
		}
	}
	#powered {
		padding: 16px 0 32px;
		.row {
			& > div {
				padding: 8px 15px;
			}
		}
	}
}