//--------------------header
#header-layout {
	position: relative;
	z-index: 99;
	.header-top .container {
		& > .row:nth-child(2) {
			height: 64px;
			padding-top: 9px;
		}
	}
	ul {
		padding: 0;
	}
	.container-logo {
		padding-left: 0;
		#logo {
			a {
				padding: 15px 0 0;
				display: flex;
				display: -webkit-box;
				display: -ms-flexbox;
				flex-wrap: wrap;
				-ms-flex-wrap: wrap;
				flex-direction: column;
				-webkit-box-orient: vertical;
				svg {
					width: 180px;
					max-width: 100%;
				}
				span {
					width: 100%;
					font-size: 12px;
					line-height: 16px;
					color: $d;
					display: block;
				}
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	.nav-search {
		padding-top: 63px;
		.search_block {
			overflow: visible;
		}
	}
	.as-title {
		white-space: normal;
	}
	#top_menu_desktop {
		padding-bottom: 8px;
		padding-top: 16px;
		.top_menu_ul {
			padding: 0;
			margin: 0;
			.top_menu_li {
				list-style-type: none;
				padding: 0 8px;
				& > a {
					font-size: 14px;
					font-weight: 400;
					font-family: $hf;
					color: $dg;
					background-color: transparent !important;
					@include transition(all 200ms ease-out);
					line-height: 20px;
					&:hover, &:focus, &:active {
						text-decoration: underline #0aa948;
					}
				}
			}
		}
	}
	#topcontacts {
		height: 56px;
		.contacts_gradient {
			position: relative;
			width: 100%;
			max-width: 392px;
			svg.border{
				width: 100%;
				height: 56px;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				font-size: 15px;
				overflow: visible;
			}
			.contacts_icon {
				width: 56px;
				height: 56px;
				border-radius: 37px;
				background: linear-gradient(149.96deg, #4CAF50 12.84%, #4DB45E 12.85%, #26A6D1 94.74%);
				.svg-icon-big {
					@include size(30px, 30px);
					sgv.call path {
						fill: $wh;
					}
				}
				.call {
					fill: #ffffff;
				}
			}
			.contacts_info {
				width: calc(100% - 56px);
				height: inherit;
				text-align: left;
				margin-left: 8px;
				border-radius: 37px;
				p {
					margin: 0;
					color: $d;
					a {
						color: $d;
					}
					&.phones {
						font-weight: 500;
						font-size: 18px;
						line-height: 24px;
						font-family: 'Rubik', sans-serif;
						span {
							&:first-child {
								margin-right: 9px;
							}
						}
					}
					&.worktime {
						font-weight: normal;
						font-size: 12px;
						line-height: 16px;
						letter-spacing: 0.85px;
					}
				}
			}
		}
	}
	#cart-compare {
		height: 100%;
		svg path {
			fill: $tc;
		}
		.cart-compare-inner {
			width: 100%;
			max-width: 392px;
			&:after {
				content: '';
				position: absolute;
				width: 1px;
				height: 80%;
				background: $lg2;
				left: 50%;
				top: 0;
			}
			& > a {
				padding-bottom: 21px;
			}
			.wrap-compare, .wrap-cart {
				width: 60px;
				height: 40px;
			}
			#compare-url, #cart-url {
				&:hover, &:active, &:focus {
					text-decoration: none;
				}
			}
		}
		#cart-count, #compare-count, #compare-count-mob {
			font-size: 18px;
			line-height: 24px;
			color: $tc;
			font-weight: 500;
		}
		.cart_pop_block svg path{
			fill: $tc2;
		}
	}
}

//--------------------home page--------------------
.page-home {
	h2 {
		margin: 0;
		padding-top: 16px;
		padding: 16px 0 0 0;
	}
	.main-column {
		.pav-container {
			padding: 32px 0 64px;
			&:nth-child(2) {
				padding: 0;
			}
			&:nth-child(4) {
				background-color: $lbg;
				background-image: url(#{$cdn-image-theme-path-base}templates/home/hits.png);
				background-repeat: no-repeat;
				background-position: calc(50% - 600px) 110px;
				box-shadow: 4px 4px 8px rgba(99, 100, 102, 0.25);
			}
			&:nth-child(5) {
				background-image: url(#{$cdn-image-theme-path-base}templates/home/orange_blue.png);
				background-repeat: no-repeat;
				background-position: 100% 135px;
			}
			&:nth-child(6) {
				position: relative;
				background-color: $lbg;
			}
			&:nth-child(6):before {
				content: '';
				background-image: url(#{$cdn-image-theme-path-base}templates/home/recommend.png);
				background-repeat: no-repeat;
				display: block;
				position: absolute;
				width: 300px;
				height: 750px;
				top: -70px;
				left: calc(50% - 780px);
			}
			&:nth-child(7) {
				background-color: $lbg;
				background-image: url(#{$cdn-image-theme-path-base}templates/home/blue-02.png);
				background-repeat: no-repeat;
				background-position: 100% 0;
			}
			&:nth-child(8) {
				background-image: url(#{$cdn-image-theme-path-base}templates/home/bck_2.jpg);
				background-repeat: no-repeat;
				background-position: center center;
				background-attachment: fixed;
				background-size: cover;
				box-shadow: 4px 4px 8px rgba(99, 100, 102, 0.25);
			}
			&:nth-child(10) {
				background-color: $lbg;
				background-image: url(#{$cdn-image-theme-path-base}templates/home/new.png);
				background-repeat: no-repeat;
				background-position: calc(50% - 635px) 55px;
				box-shadow: 4px -4px 8px rgba(99, 100, 102, 0.25);
			}
			&:nth-child(11) {
				background-color: $lbg;
			}
		}
	}
	//html on main page
	#html-on-main-page {
		padding-top: 32px;
		.text-block {
			.text-block-el {
				display: inline-flex;
				.text-block-svg {
					@include size(80px,80px);
					margin: 0 16px 0 0;
					svg {
						@include size(80px,80px);
					}
				}
				.text-block-info {
					margin-right: 20px;
					h3 {
						padding-bottom: 16px;
						margin: 0;
					}
					p {
						margin: 0;
						font-size: 16px;
					}
				}
			}
		}
		.img-block {
			position: relative;
			padding: 0;
			align-self: start;
			img {
				width: 100%;
			}
			a {
				position: absolute;
				width: 100%;
				height: 56px;
				bottom: 0;
				left: 0;
				background: linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, #202020 100%);
				border-radius: 0 0 10px 10px;
				padding: 0 16px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				&:hover {
					text-decoration: underline;
					background: $d;
				}
			}
		}
	}

	//newsletter
	.newsletter {
		.newsletter-inner {
			box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
			border-radius: 10px;
			background-color: $wh;
			padding: 0;
			.img-block {
				border-radius: 10px 0 0 10px;
				img {
					border-radius: 10px 0 0 10px;
					max-width: 100%;
				}
			}
			.content-block {
				border-radius: 0 10px 10px 0;
				padding: 0 32px;
				margin: 0 auto;
				.content-block-inner {
					p, h2 {
						width: 100%;
					}
					h2 {
						margin: 40px 0 32px;
					}
					p {
						margin: 0;
					}
					#nl_submit {
						@include size (100%, 40px);
					}
					#nl_email {
						@include size (100%, 56px);
						margin: 16px 0 24px;
					}
					#nl_form {
						.checkbox_custom {
							.input-control {
								padding-left: 30px;
								margin: 16px 0 14px 0;
							}
							span {
								color: $tc2;
							}
							a {
								color: $tc2;
								&:hover {
									color: $tc;
									text-decoration: none;
								}
							}
							#news_checkbox {
								border: 1px solid $lbg2;
								box-sizing: border-box;
								border-radius: 3px;
								position: absolute;
								@include size(24px, 24px);
								left: 0;
								background: $wh;
								svg {
									display: none;
								}
								&:hover {
									border: 2px solid $tc;
									cursor: pointer;
								}
								&.on {
									border: 2px solid $tc;
									svg {
										display: unset;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
.opacity-with-end {
	&:after {
			content: "";
			height: inherit;
			width: 238px;
			position: absolute;
			right: -100px;
			top: 0;
			background: linear-gradient(265.6deg, #F9F9F9ED 44.81%, rgba(255, 255, 255, 0.07) 64.19%);
			background: -o-linear-gradient(265.6deg, #F9F9F9ED 44.81%, rgba(255, 255, 255, 0.07) 64.19%);
	}
}
.cat-carousel {
	.owl-stage-outer {
		height: 40px;
		width: 91%;
	}
	.owl-item.active, .owl-item {
		margin-left: 1px;
		width: fit-content !important;
		width: -moz-fit-content !important;
		width: -webkit-fit-content !important;
		width: intrinsic !important;
	}
	.owl-item.active {
		&:first-child {
			.grad_background {
				background: rgba(0, 0, 0, 0) linear-gradient(179.28deg, rgb(76, 175, 80) 12.84%, rgb(77, 180, 94) 12.85%, rgb(38, 166, 209) 94.74%) repeat scroll 0% 0%
			}
		}
	}
}


//--------------------category page--------------------
.page-category, .page-product-search, .page-product-special {
	.wrapper_empty {
		padding: 20px 0 60px 0;
	}
	#page {
		background: $lbg;
		.h1_container {
			padding-bottom: 40px;
		}
	}
	.category-background {
		position: absolute;
		width: 100vw;
		height: 550px;
		background-image: url(#{$cdn-image-theme-path-base}templates/category/blue-green-category-03.png);
		background-repeat: no-repeat;
	}
	.category-header {
		padding: 32px;
		box-shadow: 4px 4px 8px rgba(99, 100, 102, 0.25);
		border-radius: 10px;
		background: $wh;
		margin-bottom: 40px;
		.refine-search {
			&:after {
				content: "";
				display: block;
				clear: both;
			}
		}
		.group-switch {
			.btn {
				background-color: unset;
				padding: 0 4px;
				&.active {
					svg path {
						fill: $tc;
					}
				}
				&:hover {
					svg path {
						fill: $tc;
					}
				}
			}
		}
		.grad_background {
			margin: 0 16px 16px 0;
		}
	}
	.product-grid {
		.products-block {
			.product-block {
				width: calc(100% / 4 - 12px);
				margin: 16px 16px 0 0;

				&:nth-child(4n+1) {
					margin-left: 0;
				}
				&:nth-child(4n) {
					margin-right: 0;
				}
			}
		}
	}
	.product-list {
		.products-block {
			.product-block {
				width: 100%;
				margin: 0 0 16px;
			}
		}
	}
}
//----------------------all pages----------------------
.grad_background {
	height: 40px;
	background: $lbg;
	width: fit-content;
	padding: 0 2px;
	border-radius: 20px;
	&.active {
		background: linear-gradient(179.28deg, #4CAF50 12.84%, #4DB45E 12.85%, #26A6D1 94.74%);
	}
	&:hover, &:active, &:focus {
		background: linear-gradient(179.28deg, #4CAF50 12.84%, #4DB45E 12.85%, #26A6D1 94.74%);
	}
	.sub_categories, .sub_category {
		height: 36px;
		background-color: $wh;
		width: fit-content;
		border-radius: 18px;
		a {
			color: $dg;
			height: inherit;
			padding: 0 20px;
			&:hover, &:active, &:focus {
				text-decoration: none;
			}
		}
	}
}

// footer
#footer {
	background: $wh;
	.ft-phone {
		span {
			&:first-child {
				letter-spacing: 0.3px;
			}
		}
	}
}
.payment {
	.svg-item {
		width: inherit;
		height: inherit;
	}
}