.breadcrumb {
	> li {
		display: inline-block;
		+ li:before {
			//content: "#{$breadcrumb-separator}\00a0"; // Unicode space added since inline-block means non-collapsing white-space
			content: "-";
			padding: 0 5px;
			color: $dg;
		}
		a {
			color: $dg;
			&:hover {
				text-decoration: none;
				color: $tc;
			}
		}
	}
	> .active {
		font-weight: 600;
	}
}