.page-product, .quick-view {
	.owl-carousel-play .owl-carousel {
		margin: 0;
		border: none;
	}

	.products-block {
		.owl-carousel {
			margin: 0;
		}
	}
	.product-info-bg {
		margin: 40px -15px 60px;
	}
	.image-additional {
		width: 80%;
		padding: 0 50px;
		#image-additional-carousel {
			.owl-item {
				padding: 0 5px;
			}
			.owl-nav {
				.owl-prev {
					left: -50px;
					svg {
						transform: rotate(180deg);
					}
				}
				.owl-next {
					right: -50px;
				}
				.owl-next, .owl-prev {
					@include size(40px, 40px);
					position: absolute;
					top: 7px;
					display: flex;
					align-items: center;
					justify-content: center;
					background: $whwg;

					svg {
						@include size(40px, 40px);
						path {
							fill: $dg;
						}
					}
				}
			}
		}
	}
	.rating-compare {
		padding-bottom: 32px;
		.title-product {
			width: 100%;
			font-weight: 500;
			margin: 8px 0 16px;
		}
		.prod_rating {
			width: 100%;
			span {
				color: $dg;
				font-weight: 500;
				&.sep {
					margin: 0px 5px;
				}
			}
			.stars {
				svg {
					margin-right: 4px;
					&:last-child {
						margin-right: 8px;
					}
				}
			}
			.compare {
				margin: 0 22px;
				svg {
					width: 18px;
					height: 18px;
				}
				span {
					font-weight: 500;
				}
			}
			.stock {
				flex: 1;
				font-weight: 500;
				color: $tc;
				-ms-flex-item-align: end;
				-ms-grid-row-align: end;
				align-self: end;
				-webkit-box-pack: end;
				-ms-flex-pack: end;
				justify-content: flex-end;
				padding-right: 10px;
			}
		}
	}
	.block-act {
		width: 60%;
		padding: 0 50px 0 0;
		.price {
			.price-new {
				display: block;
				font-weight: bold;
				font-size: 40px;
				line-height: 50px;
				white-space: nowrap;
			}
			.special {
				width: 100%;
				height: 24px;
				.price-old {
					font-weight: 300;
					font-size: 14px;
					line-height: 20px;
					text-decoration-line: line-through;
					color: $dg;
					margin-right: 10px;
					white-space: nowrap;
				}
				.profit {
					font-weight: normal;
					font-size: 12px;
					line-height: 16px;
					color: $tc;
					background: $lbg;
					padding: 4px 8px;
					border-radius: 18px;
				}
			}
			.quantity {
				padding: 15px 0;
				input {
					border: none;
					font-weight: bold;
					font-size: 18px;
					line-height: 24px;
					color: $d;
					width: 55px;
				}
				.plus-minus {
					@include size(30px, 40px);
					.add-up {
						transform: rotate(-90deg);
						&:hover {
							cursor: pointer;
						}
					}
					.add-down {
						transform: rotate(90deg);
						&:hover {
							cursor: pointer;
						}
					}
				}
			}
		}
		.cart {
			.btn {
				width: 100%;
				margin-bottom: 16px;
			}
		}
		.button-link {
			margin-bottom: 8px;
		}
	}
	.button-credit {
		padding: 0 19px;
		font-size: 13px;
		flex: 0;
	}
	.cart.flex {
		justify-content: space-between;
	}
	.button-link {
		border: none;
		background-color: unset;
		padding: 0;
		span {
			color: $dg;
			margin-left: 5px;
		}
		&:hover {
			text-decoration: underline;
			cursor: pointer;
		}
	}
	.block-info {
		width: 40%;
		.list-unstyled {
			padding: 12px;
			border-left: 1px solid $lbg2;
			width: fit-content;
			margin-right: 0px;
			li {
				padding-bottom: 10px;
				.info-key {
					font-weight: 500;
				}
				.info-val {
					display: block;
					color: #737373;
					img {
						display: none;
					}
				}
			}
		}
	}
	.block-faq {
		width: 100%;
		margin-top: 28px;
		.icon {
			position: relative;
			margin: 0 14px 0 0;
			width: 99px;
			max-height: 104px;
			&:last-child {
				margin: 0;
			}
			img {
				position: relative;
				z-index: 1;
				width: 100%;
				height: auto;
				-webkit-transition: all .2s;
				-o-transition: all .2s;
				transition: all .2s;
			}
			img:first-child {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 2;
				width: 100%;
				height: 100%;
				opacity: 0;
			}
			&:hover img {
				opacity: 0
			}
			&:hover img:first-child {
				opacity: 1
			}
		}
	}
	.product-description {
		padding: 39px 0 0;
		background-color: $lbg;
		box-shadow: 2px -2px 4px rgba(0, 0, 0, 0.1);
		.tabs-container {
			position: relative;
			top: 25px;
			ul {
				li {
					background-color: $tc;
					padding: 0;
					margin-right: 8px;
					border-radius: 5px;
					border: none;
					&:hover {
						background-color: $lgr2;
					}
					a {
						padding: 14px 24px 39px;
						p {
							padding: 0;
							margin: 0;
							color: $wh;
							font-weight: 500;
							font-size: 18px;
							line-height: 24px;
						}
					}
					&.active {
						padding: 5px 0 0;
						position: relative;
						z-index: 2;
						a {
							padding: 9px 24px 39px;
							background-color: $wh;
							p {
								color: $tc;
							}
						}
					}
				}
			}
		}
		#product-info {
			position: relative;
			z-index: 1;
			background: $wh;
			box-shadow: 4px 4px 8px rgba(99, 100, 102, 0.25);
			border-radius: 10px;
			padding: 24px;
			#tab-description {
				font-size: 16px;
			}
			#tab-specification .table tbody tr {
				min-height: 40px;
				border: none;
				td {
					border: none;
					min-width: 300px;
					max-width: 300px;
				}
				&:nth-child(2n+1) {
					background: $lbg;
				}
			}
			#tab-downloads {
				ul {
					margin: 0;
					padding: 0;
					li {
						list-style-type: none;
					}
				}
				a {
					color: $d;
					svg {
						margin-right: 10px;
					}
					&:active, &:focus {
						color: $d;
					}
					&:hover {
						text-decoration: none;
						span {
							border-bottom: 1px solid $d;
						}
					}
				}
			}
			#review {
				.review-item {
					padding: 32px 0 16px;
					border-bottom: 2px solid $lbg;
					&:first-child {
						padding-top: 0;
					}
					.review-block {
						margin-bottom: 24px;
						.review-title {
							width: 160px;
							margin: 0;
						}
						.review-text {
							margin: 0;
						}
						.review-date {
							flex: 1 1 100px;
							text-align: right;
						}
						.rating > svg {
							margin-right: 4px;
						}
					}
					.review-description {
						.review-block {
							&:last-child {
								margin-bottom: 0;
							}
							.review-text {
								width: calc(100% - 224px);
							}
						}
					}
				}
			}
			#form-review-container {
				padding-top: 20px;
			}
			#review_btn {
				height: 40px;
				line-height: 38px;
				margin-top: 24px;
			}
			#form-review-container {
				.set-rating {
					display: flex;
					flex-direction: row-reverse;
					justify-content: flex-end;
					margin-top: 6px;
					margin-bottom: 2px;
					height: 16px;
					width: max-content;
					div {
						svg > path {
							fill: $lg;
						}
						&.selected {
							svg > path {
								fill: $tc;
							}
						}
						&:hover {
							svg path {
								fill: $lg;
							}
							cursor: pointer;
						}
						&:hover, &:hover ~ div {
							svg > path {
								fill: $tc;
							}
						}
					}
				}
				.recaptcha_container {
					.control-label {
						display: none;
					}
				}
			}
		}
		.tab-content {
			margin-bottom: 80px;
		}
		.related-product {
			margin: 32px 0 80px;
		}
		.container {
			.container {
				padding-left: 0;
			}
		}
	}
}

.quick-view {
	.title-product {
		margin-bottom: 10px;
	}
	.product-info-bg {
		margin: 0px;
	}
	.product-meta {
		display: flex;
		flex-wrap: wrap;
	}
	.image-container {
		width: 41%;
		.image-additional {
			position: absolute;
			bottom: 10px;
			width: 100%;
		}
	}
	.list-unstyled {
		margin-bottom: 10px;
	}
	.product-view {
		.price {
			.price-new {
				display: block;
				font-weight: bold;
				font-size: 32px;
				line-height: 50px;
			}
			.price-old {
				text-decoration-line: line-through;
				color: $dg;
			}
			.price-list {
				min-height: 75px;
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
			}
			.old-price-profit {
				min-height: 24px;
			}
			.profit {
				font-weight: normal;
				font-size: 12px;
				line-height: 16px;
				color: $tc;
				background: $lbg;
				padding: 4px 8px;
				border-radius: 18px;
			}
		}
		.info-key {
			font-weight: 500;
		}
		.compare-stock {
			flex-wrap: nowrap;
			justify-content: space-between;
			.compare {
				svg {
					width: 18px;
					height: 18px;
				}
			}
		}
		.stock {
			font-weight: 500;
			color: $tc;
		}
		.quantity {
			padding: 0px 15px;
			margin-bottom: auto;
			input {
				border: none;
				font-weight: bold;
				font-size: 18px;
				line-height: 24px;
				color: $d;
				width: 55px;
			}
			.plus-minus {
				width: 30px;
				height: 40px;
				.add-up {
					transform: rotate(-90deg);
				}
				.add-down {
					transform: rotate(90deg);
				}
			}
		}
		.cart {
			padding-top: 15px;
		}
	}
}

.storage_widget {
	margin-top: 40px;
	.sw_top {
		#storage_time {
			color: $tc;
		}
	}
	.sw_center {
		margin: 16px 0;
		svg {
			float: left;
			height: 36px;
		}
		.storage_address {
			float: left;
			font-weight: 600;
			font-size: 18px;
			line-height: 36px;
			margin: 0 32px 0 8px;
		}
		.sw_btn {
			button {
				border: 2px solid $tc;
				box-sizing: border-box;
				border-radius: 59px;
			}
		}
	}
}
