
/*Text Colors
------------------------------------*/
.text-default {
	color: $brand-default !important;
}

.text-primary {
	color: $brand-primary !important;
}

.text-info {
	color: $brand-info !important;
}

.text-danger {
	color: $brand-danger !important;
}

.text-warning {
	color: $brand-warning !important;
}

.text-success {
	color: $brand-success !important;
}

h1 {
	font-size: 32px;
	line-height: 40px;
	font-weight: 500;
}
h2 {
	font-size: 40px;
	line-height: 50px;
	font-weight: 500;
}
h3 {
	font-size: 24px;
	line-height: 32px;
	font-weight: 500;
}
h4 {
	font-size: 18px;
	line-height: 24px;
	font-weight: 500;
}
h5 {
	font-size: 18px;
	line-height: 24px;
	font-weight: normal;
}
p.p1, a.p1, span.p1, td.p1 {
	font-size: 16px;
	line-height: 24px;
	font-weight: normal;
}
p.p2, a.p2, span.p2, td.p2 {
	font-size: 14px;
	line-height: 20px;
	font-weight: normal;
}
p.p3, a.p3, span.p3, td.p3 {
	font-size: 12px;
	line-height: 16px;
	font-weight: normal;
}
p.additional {
	font-size: 12px;
	line-height: 16px;
	font-weight: normal;
}




/*Background Colors
------------------------------------*/
.bg-primary {
	background-color: $brand-primary !important;
}

.bg-info {
	background: $brand-info !important;
}

.bg-danger {
	background: $brand-danger !important;
}

.bg-warning {
	background: $brand-warning !important;
}

.bg-success {
	background: $brand-success !important;
}

.accordion {
	.panel {
		@include box-shadow(0 0px 0px rgba(0, 0, 0, .05));
	}
	.panel-heading {
		padding: 15px;
		position: relative;
		a {
			text-decoration: none;
			font-weight: bold;
			&:before {
				content: "-";
				font-size: 22px;
				font-weight: 600;
				@include size(20px, 20px);
				text-align: center;
				line-height: 16px;
				display: inline-block;
				color: $bo-accordion-color;
			}
			&.collapsed:before {
				content: "+";
				font-size: 18px;
				line-height: 20px;
			}
		}
	}
	.panel-body {
		border-top-color: transparent !important;
	}

	&.collapse-right {
		.panel-heading {
			a:before {
				position: absolute;
				right: 20px;
			}
		}
	}

	&.collapse-color {
		.panel-heading a {
			&:before {
				color: #fff;
				background: $bo-accordion-background;
			}
		}
	}
	&.noborder {
		.panel-heading {
			padding-left: 0;
			padding-right: 0;
		}
		.panel, .panel-heading, .panel-body {
			background-color: transparent;
			border: none;
		}

	}
	&.nobackground {
		.panel, .panel-heading, .panel-body {
			background-color: transparent;
		}
	}
}

.breadcrumbs {
	padding: 32px 0 24px 0;
	margin-bottom: 0;
	list-style: none;
	background-color: $lbg;
	border-radius: $border-radius-base;
	.breadcrumb {
		margin: 0;
		padding: 0 2px;
	}
	h2 {
		margin: 0;
		float: left;
		font-weight: 400;
		position: relative;
	}
	.breadcrumb-links {
		margin: 0;
		position: relative;
		padding: 0 0 10px;
		border-bottom: 1px solid $border-color-base;
		> li {
			display: inline-block;
			a {
				text-transform: uppercase;
				font-weight: 500;
				font-size: $font-size-base - 2;
			}
			+ li:before {
				color: #757575;
				content: "\f105";
				font-family: FontAwesome;
				padding: 0 15px;
				font-size: 14px;
			}
			&:last-child {
				a {
					color: $black;
				}
			}
		}
		> .active {
			color: $link-hover-color;
		}
	}
}

.breadcrumbs-left {
	h2 {
		float: none;
		text-align: left;
	}
	.breadcrumb-links {
		text-align: left;
	}
}

.heading {
	z-index: 1;
	position: relative;
	text-align: center;
	h1, h2, h3, h4, h5, h6 {
		text-transform: uppercase;
	}
	p, small {
		margin-bottom: 15px;
		display: inline-block;
	}
	&.heading-light {
		color: #eee;
		h1, h2, h3, h4, h5, h6 {
			color: #fff;
		}
		p, small {
			color: #eee;
		}
	}
}

.list-unstyled {
	list-style: none;
	padding-left: 0;
}

@mixin translateY($y) {
	-webkit-transform: translateY($y);
	-ms-transform: translateY($y); // IE9 only
	-o-transform: translateY($y);
	transform: translateY($y);
}

.success {
	display: none;
	width: 100%;
	max-width: 750px;
	margin-left: -450px;
	background: white;
	border: 1px solid #E4E3E3;
	border-radius: 10px;
	padding: 32px 39px 50px 32px;
	left: 50%;
	top: 22%;
	font-size: 16px;
	color: #333333 !important;
	-webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	z-index: 15000;
	position: fixed;
	.title, .name, .price {
		font-family: 'Rubik', sans-serif;
		font-style: normal;
		font-weight: normal;
	}
	.name, .price {
		padding-bottom: 24px;
	}
	.title {
		font-weight: 500;
		font-size: 24px;
	}
	.item-box {
		padding-top: 32px;
		padding-bottom: 24px;
		img {
			max-width: 100%;
			width: 144px;
			height: 100%;
			padding-right: 16px;
		}
		.item-data-box {
			.name {
				font-size: 18px;
				line-height: 24px;
			}
			.price {
				font-size: 16px;
				line-height: 24px;
			}
			.qty {
				height: 48px;
				.plus, .minus {
					width: 64px;
					svg {
						margin: auto;
					}
				}
				.quantity {
					width: 48px;
					padding: 10px 0;
				}
				& > div {
					border: 1px solid $lbg2;
					box-sizing: border-box;
					border-radius: 3px;
					&:nth-child(1) {
						border-radius: 3px 0px 0px 3px;
					}
					&:nth-child(2) {
						border-left: 0;
						border-right: 0;
						border-radius: 0;
					}
					&:nth-child(3) {
						border-radius: 0px 3px 3px 0px;
					}
				}
			}
		}
	}
	.btn {
		text-transform: none;
		&.buy1click {
			margin-left: 16px;
			margin-right: 54px;
			width: 272px;
		}
	}
	a {
		text-decoration: none !important;
		font-size: 16px;
		&:hover {
			text-decoration: underline;
		}
	}
	.close {
		opacity: 1;
		float: right;
		position: absolute;
		top: 20px;
		right: 20px;
		@include size(20px,20px);
		cursor: pointer;
	}
	.success_button {
		@include transition(0.2s all);
		background: $theme-color;
		border: none;
		display: inline-block;
		cursor: pointer;
		color: white !important;
		font-size: 15px;
		font-weight: bold;
		padding: 6px 24px;
		text-decoration: none;
		&:hover {
			text-decoration: none !important;
			background: $bk;
		}
		&:active {
			position: relative;
			top: 1px;
		}
	}
}

.form_callback_form .callback_button {
	border: 1px solid $tc;
	background: $tc;
	border-radius: 59px;
	transition: all 200ms ease 0.2s;
}