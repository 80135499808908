@media (min-width: 1199px) {
	.cart-compare-mob, .seaarch-mob, .header-menu, .arrow-action {
		display: none;
	}
}

//mobile screen
@media (max-width: $screen-xs-max) {
	//product
	.zoomContainer {
		display: none;
	}
	.page-product {
		.image-additional {
			width: 100%;
		}
		.block-act {
			padding: 30px 0 0;
		}
		.block-info {
			.list-unstyled {
				margin: 0;
				padding: 24px 0;
				border: none;
			}
		}
		.product-info-bg {
			margin: 40px 0 60px;
		}
		.product-info {
			.rating-compare {
				.title-product {
					margin: 8px 0 16px;
				}
				.prod_rating {
					flex-wrap: wrap;
				}
			}
		}
		.product-description {
			padding: 0;
			& > .container {
				padding: 0 !important;
				.container {
					padding: 0 25px;
				}
			}
			#product-info {
				border-radius: unset;
				box-shadow: none;
				padding: 0;
				.container {
					padding: 0 25px;
					& > .row > div {
						padding: 0;
					}
				}
				.dropdown-description-title {
					padding: 0 25px;
					height: 40px;
					background-color: $lbg;
					border-bottom: 1px solid $lbg2;
					&.visible-xs {
						display: flex !important;
					}
					h4 {
						color: $tc;
						margin: 0;
					}
				}
				.dropdown-description {
					padding: 24px 25px 32px;
				}
				#tab-specification .table tbody tr {
					display: flex;
					flex-wrap: wrap;
					td {
						&:first-child {
							width: 100%;
							padding-bottom: 0;
						}
						&:last-child {
							color: $dg;
							padding-top: 0;
						}
					}
				}
				#review {
					.review-item {
						.review-block {
							flex-wrap: wrap;
							margin-bottom: 8px;
							.review-title {
								width: 100%;
							}
							.review-text {
								width: 100%;
							}
						}
						.review-header .review-block:first-child {
							.review-title {
								order: 1;
								width: 50%;
							}
							.review-author {
								order: 3;
							}
							.review-date {
								order: 2;
								width: 50%;
							}
						}
						.review-description {
							.review-block {
								.review-text {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
		.tab-content > .tab-pane {
			display: block;
			visibility: visible;
		}
		.storage_widget {
			.sw_center {
				.sw_btn {
					width: 100%;
					button {
						width: 100%;
						margin: 10px 0 0;
					}
				}
				.storage_address {
					line-height: 24px;
					width: calc(100% - 90px);
				}
			}
		}
	}
}

//any screen other than mobile
@media (min-width: $screen-sm-min) {
	//product
	.page-product {
		.tab-content > .tab-pane {
			& > .collapse {
				display: block;
				visibility: visible;
				height: auto !important;
			}
		}
	}
}

//tablet
@media (max-width: 1199px) {
	h1, h2, h3 {
		font-weight: 500;
		color: $d;
	}
	h1 {
		font-size: 24px;
		line-height: 30px;
		letter-spacing: 0.05em;
		margin: 0;
	}
	.success {
		width: 80%;
		margin-left: -40%;
	}
	.cart_pop_block {
		top: 40px;
	}
	.row-offcanvas-left {
		&.active {
			left: 100%;
		}
		.sidebar-offcanvas {
			left: -100%;
		}
	}
	.sidebar-offcanvas {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
	}
	.row-offcanvas {
		position: relative;
		-webkit-transition: all 0.25s ease-out;
		-o-transition: all 0.25s ease-out;
		transition: all 0.25s ease-out;
	}
	#header-layout {
		.container {
			width: auto;
		}
		.cart-compare-mob, .seaarch-mob {
			display: block;
		}
		.logo-container {
			margin: auto;
			margin-left: 15px;
			padding-left: 0;
			#logo {
				a {
					display: flex;
					padding-top: 0;
					flex-direction: row;
					justify-content: space-between;
					flex-wrap: nowrap;
					svg {
						height: 56px;
					}
					span {
						width: 131px !important;
						padding: 10px 0 10px 5px;
					}
				}
			}
		}

		#topcontacts {
			margin: auto;
		}
		#top_menu_desktop, #pav-megamenu, .holiday_works, .toggle-btn-search {
			display: none;
		}
		#searchtop {
			background: $wh;
			input {
				background: $wh;
			}
		}
		.header-top {
			padding-top: 16px;
			.container {
				.row {
					&:nth-child(2) {
						height: auto;
					}
					&:first-child {
						height: auto;
						padding-bottom: 16px;
						display: flex;
						flex-wrap: nowrap;
						justify-content: space-between;
					}
				}
			}
		}
		.header-menu {
			background: $wh;
			.container {
				height: 64px;
				padding-top: 10px;
				padding-left: 0;
				.seaarch-mob {
					padding: 0;
					input {
						border-bottom: 2px solid $tc;
					}
					.svg-d {
						fill: $tc;
					}
				}
			}
		}
	}
	.page-home {
		.featured-categories {
			.panel-body {
				.feature-cat {
					&:nth-child(6n+1), &:nth-child(6n+6) {
						.image {
							.img-responsive {
								width: auto;
							}
						}
					}
				}
			}
		}
		#html-on-main-page {
			.img-block {
				img {
					height: 100%;
				}
			}
		}
		.newsletter {
			.newsletter-inner {
				.content-block {
					.content-block-inner {
						h2 {
							margin: 16px 0 24px;
						}
					}
				}
			}
		}
		#sidebar-main {
			.bannercontainer {
				.fullwidthbanner {
					margin-top: 0;
				}
			}
		}
	}
	.page-product {
		font-style: normal;
		font-weight: normal;
		#product {
			padding-right: 72px;
			.price {
				.special {
					.price-old {
						font-size: 16px;
						line-height: 24px;
					}
					.profit {
						font-size: 12px;
						line-height: 16px;
						letter-spacing: 0.05em;
					}
				}
			}
			.cart {
				#button-cart, .buy1click {
					text-transform: capitalize;
				}
			}
			.btn-links {
				color: $dg;
				font-size: 16px;
				line-height: 24px;
			}
		}
		.storage {
			.storage_widget {
				.sw_btn {
					text-transform: capitalize;
				}
			}
		}
	}
	.page-category, .page-product-search, .page-product-special {
		.product-block {
			.product-meta {
				.bottom {
					.cart {
						button {
							padding-right: 0;
						}
					}
				}
			}
		}
	}
	.page-information {
		h1 {
			margin-top: 20px;
		}
	}
	.product-markdown-view {
		#product {
			padding-right: 16px;

			.btn-links {
				flex-direction: column;
				gap: 16px;
			}
		}
		.btn-grooup-ci {
			flex-direction: column;
		}
	}
}

//  992px
@media (max-width: $screen-md) {
	.page-home {
		.background-pictures-1, .background-pictures-2 {
			&:after {
				background: transparent !important;
				width: 0 !important;
				height: 0 !important;
			}
		}
		.newsletter {
			.content-block {
				h2 {
					font-weight: 500;
					font-size: 32px;
					line-height: 40px;
					color: $d;
				}
			}
			.img-block {
				width: 65%;
				overflow: hidden;
				img {
					max-width: unset !important;
				}
			}
		}
		#html-on-main-page {
			.text-block, .img-block {
				width: 100%;
				padding: 0;
			}
			.img-block {
				margin: 0 15px;
				max-height: 552px;
				overflow: hidden;
				border-radius: 10px;
			}
		}
		.featured-categories {
			.panel-body {
				.feature-cat {
					.image {
						.img-responsive {
							margin-left: -100px;
						}
					}
				}
			}
		}
	}
	.page-category, .page-product-search, .page-product-special {
		.product-grid {
			.products-block {
				.product-block {
					width: calc(100% / 3 - 16px);
					&:nth-child(3n) {
						margin-right: 0;
					}
					&:nth-child(3n+1) {
						margin-left: 0;
						margin-right: 16px;
					}
					&:nth-child(4n+1) {
						margin-left: 0;
					}
					&:nth-child(4n) {
						margin-right: 16px;
					}
				}
			}
		}
	}
	.page-information-payment {
		h2 {
			font-size: 18px !important;
			line-height: 22px !important;
			letter-spacing: 0.05em;
		}
		#payment {
			p {
				font-size: 14px;
			}
			.webmoney_left__ul {
				margin: auto;
			}
			.no-cash {
				.no-cash_right {
					.payment_grid {
						.gr {
							width: 100%;
						}
						#feature1, #feature2, #feature3, #feature4, #feature5 {
							left: -100%;
						}
					}
				}
			}
			.terminals {
				background: transparent;
				.terminals-5_left {
					padding-right: 10%;
					padding-top: 10%;
				}
			}
			.yandex-money {
				.yandex-money_right__blueBackground {
					margin-top: 5%;
				}
				.yandex-money_left__lastRow {
					padding: 5% 15px;
				}
				.yandex-money_left__mockup {
					text-align: center;
				}
			}
		}
	}
	.page-product {
		.product-view {
			padding-right: 30px;

			&.product-markdown-view {
				padding: 15px;
			}
		}
	}
	#news_list {
		.widget_news_item {
			width: calc(100% / 3 - 10px);
		}
	}
	.information-news-item {
		.container-inside {
			.row {
				display: flex;
				flex-wrap: wrap;

				.news_column {
					width: 100%;
					order: 2;
				}
				#content {
					width: 100%;
					order: 1;
				}
			}
		}

	}
	.advantages-home {
		flex-wrap: wrap;

		.item {
			max-width: 320px;
			margin-bottom: 16px;

			&:nth-child(2) {
				margin-right: 0;
			}
		}
	}
	.advantages-home {
		.item {
			width: calc(100% / 2);
		}
	}
}

//  768px
@media (max-width: $screen-sm) {
	// header
	#header-layout {
		.header-top {
			#topcontacts {
				.border {
					display: none !important;
				}
				.contacts_icon {
					width: 40px !important;
					height: 40px !important;
					.svg-icon-40 {
						width: 32px;
						height: 32px;
						.call {
							fill: #fff;
						}
					}
				}
				.contacts_info {
					.phones {
						font-size: 17px;
					}
					.worktime {
						letter-spacing: 1.5px;
					}
				}
			}
			.logo-container {
				margin: auto;
				#logo {
					svg {
						width: 117px;
					}
				}
			}

		}
		.header-menu {
			.main-menu {
				.canvas-menu {
					display: flex;
					flex-wrap: nowrap;
					height: 40px;
					flex-direction: row;
					width: 100%;
					justify-content: space-around;
					padding: 10px;
					.first-text {
						font-weight: 500;
						font-size: 18px;
						line-height: 24px;
					}
				}
			}
		}
	}
	.page-home {
		#html-on-main-page {
			.text-block, .img-block {
				width: 100%;
			}
		}
		.newsletter {
			padding: 49px 15px 80px 0;
			.content-block {
				.content-block-inner {
					h2 {
						margin: 32px 0 16px 0 !important;
					}
				}
			}
			.img-block {
				img {
					height: 100%;
				}
			}
		}
		.featured-categories {
			.panel-body {
				.feature-cat {
					width: 50%;
					&:nth-child(6n+1), &:nth-child(6n+6) {
						.image {
							.img-responsive {
								margin-left: -50%;
							}
						}
					}
					&:nth-child(5) {
						.image {
							& > a {
								left: -100px;
							}
						}
					}
					&:nth-child(3) {
						.image {
							& > a {
								left: 0;
							}
						}
					}
				}
			}
		}
		.widget-products {
			.product-block {
				&:first-child {
					margin-left: 0;
				}
				&:nth-child(2) {
					margin-top: 0;
				}
			}
		}
		.main-column {
			.pav-container {
				&:nth-child(3) {
					padding-bottom: 0;
				}
				padding-bottom: 32px;
			}
		}
		.cat-carousel {
			.owl-stage-outer {
				width: 85%;
			}
		}
	}
	.page-product {
		.product-view {
			padding-left: 56px;
		}
		.rating-compare {
			.stock {
				justify-content: flex-start;
			}
		}
		.product-description {
			.container {
				padding: 0 54px;
				.tabs-container {
					.nav-tabs {
						padding: 0;
					}
				}
			}
		}
	}
	.page-category, .page-product-search, .page-product-special {
		.product-filter {
			.display {
				display: none;
			}
		}
		.product-grid {
			.products-block {
				.product-block {
					width: calc(100% / 2 - 12px);
					&:nth-child(2n) {
						margin-right: 0;
					}
					&:nth-child(2n+1) {
						margin-right: 16px;
					}
					.block-img {
						.image {
							height: 100%;
						}
						.stickers {
							font-size: 12px;
							line-height: 16px;
						}
					}
				}
			}
		}
		.product-list {
			.product-block {
				padding-bottom: 24px;
				.block-img {
					.product-sticker {
						bottom: -9px;
					}
				}
				.product-meta {
					font-family: Rubik !important;
					font-style: normal !important;
					font-weight: normal !important;
					.name {
						color: $d;
						font-size: 18px;
						line-height: 24px;
						min-height: 75px;
					}
					.description {
						color: $d;
					}
					.cart {
						flex-wrap: nowrap;
						padding-bottom: 0 !important;
						.in-cart {
							font-weight: normal;
							width: 84px;
						}
					}
					.top {
						&:after {
							bottom: 0;
						}
						.qwt-compare-list {
							svg {
								path {
									fill: $dg;
								}
							}
						}
					}
					.bottom {
						.price-display-list {
							font-family: $font-family-sans-serif;
						}

					}
				}
			}
		}
	}
	.page-checkout-buy {
		h1 {
			margin: 0;
			padding-bottom: 40px;
			font-size: 32px;
			line-height: 40px;
			letter-spacing: 0.05em;
		}
		.container {
			padding: 0 24px;
		}
		.header-top {
			.container {
				padding: 0;
			}
		}
		.breadcrumbs {
			.container {
				padding: 0 38px;
			}
		}
		.main-columns {
			width: auto;
			#buy_form_container {
				.row {
					& > div {
						width: 100%;
						&:first-child {
							border-radius: 10px 10px 0 0;
							border-bottom: 0;
							.shopping-cart-table {
								border-radius: 10px 10px 0 0;
							}
						}
						&:last-child {
							border-radius: 0 0 10px 10px;
							border-top: 1px solid $lbg2;
							.container-total-item {
								width: 90% !important;
							}
						}
						.th-title {
							display: none;
						}
					}
				}
			}

			#checkout-form {
				#personal-info, #payment-info, #comment-bot, #agree-info {
					width: 100%;
				}
				#delivery-info {
					#shipping_methods {
						flex-direction: column;
						& > div {
							width: 100%;
						}
						.radio_custom {
							.method-description {
								min-height: 80px;
							}
							&:nth-child(3) {
								border-radius: 0 0 10px 10px;
							}
						}
					}
				}
			}
		}

	}
	.page-information {
		#content {
			padding-right: 30px;
		}
	}
	.quick-view {
		width: 100%;
		.image-container {
			width: 100%;
			.image-additional {
				position: relative;
				margin: auto;
				bottom: 30px;
			}
		}
		.product-view {
			width: 100%;
			.cart {
				padding-top: 0;
			}
		}

	}
	.success {
		.btn {
			margin: 0 10px !important;
			width: auto !important;
		}
	}
	#news_list {
		.widget_news_item {
			width: calc(100% / 2 - 10px);
		}
	}
}

// 767px
@media (max-width: $screen-xs-max) {
	#header-layout {
		.holiday_works {
			display: block;
			margin: 0 10px;
		}
	}
	.fancybox-skin {
		padding: 24px 16px !important;
	}
	.holiday_table {
		.hw_col {
			font-size: 14px !important;
			line-height: 14px !important;
		}
	}
	#payment {
		.terminals {
			.terminals-1_left__description {
				padding-top: 5px;
				padding-left: 0;
			}
			.terminals-2_right, .terminals-3_left, .terminals-4_right {
				padding-top: 50px;
				padding-right: 0;
			}
			.terminals-3_left, .terminals-4_right, .terminals-5_left {
				p {
					padding-left: 20px;
				}
			}
			.terminals-3_right__image {
				border: none !important;
				img {
					max-width: 100%;
					width: 30% !important;
					height: auto !important;
					position: relative !important;
					left: 0;
					transform: none !important;
				}
			}
		}
	}
	.advantages-home {
		.item {
			width: 320px;
		}
	}
	.page-product {
		.product-view {
			padding: 0 8px;

			.cart {
				&.flex {
					flex-direction: column;
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
	//product
	.zoomContainer {
		display: none;
	}
	.page-product {
		.image-additional {
			width: 60%;
			padding: 0 50px;
		}
		.product-description {
			.container > .container {
				width: unset;
				padding: 0;
			}
			.tabs-container {
				ul {
					li {
						a {
							padding: 14px 12px 39px;
						}
					}
				}
			}
		}
		.block-act, .block-info {
			padding: 30px 0 0;
		}
	}
}

@media (max-width: 680px) {
	#header-layout {
		.header-top {
			.row {
				#topcontacts {
					.contacts_info {
						width: 250px;
						.phones {
							font-size: 14px !important;
						}
						.worktime {
							letter-spacing: normal !important;
						}
					}
				}
				.flex-wrap:last-child {
					width: 50%;
				}
			}
		}
	}
	.page-category {
		#mfilter-top {
			.mfilter-btn-box {
				padding: 24px 0 16px 0;
			}
		}
	}
	.success {
		div {
			&:nth-child(3), &:nth-child(4) {
				display: flex;
				float: none !important;
				margin-bottom: 10px;
			}
		}
		.btn {
			width: 100% !important;
		}
	}
	.page-home {
		.pts-newsletter {
			.img-block {
				img {
					margin-left: -120px;
				}
			}
			#nl_submit {
				margin-bottom: 30px;
			}
		}
	}
	.page-checkout-buy {
		#buy_form_container {
			.row {
				display: flex !important;
			}
		}
	}
}

@media (max-width: 580px) {
	.success {
		top: 5%;
		padding-left: 10px;
		padding-right: 10px;
		text-align: center;
		.title {
			font-size: 20px;
		}
		.item-box {
			flex-direction: column;
			img {
				margin: auto;
				width: 223px;
				padding-bottom: 20px;
				padding-right: 0;
			}
			.item-data-box {
				.name {
					text-align: center;
				}
				.price {
					text-align: center;
					font-size: 20px;
				}
			}
		}
	}
	.compare-success {
		.pull-right, .pull-left {
			width: 100%;
			padding-top: 10px;
			a.btn {
				margin: 0 !important;
			}
		}
	}
	.row-offcanvas-left {
		.sidebar-offcanvas {
			left: -110% !important;
			width: 100%;
			background: $wh;
		}
		&.active {
			left: 100%;
			.sidebar-offcanvas {
				left: -100% !important;
				z-index: 9;
				.offcanvas-inner {
					width: 100%;
				}
				.offcanvas-btn-close {
					right: 16px !important;
				}
			}
			.ocwall {
				display: none;
			}
		}
	}
	.page-product .block-faq {
		flex-wrap: wrap;
		.icon {
			position: relative;
			margin: 0 14px 14px 0;
			width: calc(100% / 3 - 14px);
			&:nth-child(3n) {
				margin: 0 0 14px 0;
			}
		}
	}
	#header-layout #cart-compare .cart-compare-inner > a {
		padding-bottom: 0;
	}
	#header-layout {
		.header-top {
			padding-top: 0;
			.logo-contacts-box {
				padding-top: 0 !important;
				padding-bottom: 0 !important;
				flex-wrap: wrap !important;
				justify-content: center !important;
				flex-direction: column-reverse;
				.container-logo {
					padding-bottom: 8px;
					width: 100% !important;
					padding-top: 8px;
					.logo {
						svg {
							height: 40px;
						}
					}
				}
				.container-contacts {
					background: $wh;
					width: auto !important;
					#topcontacts {
						.contacts_icon {
							background: transparent;
							.svg-icon-40 {
								width: 24px;
								height: 24px;
								.call {
									fill: $tc;
								}
							}
						}
						.contacts_info {
							margin-left: 0;
						}
					}
				}
			}
		}
		.header-menu {
			.container {
				height: 48px;
				padding-top: 0;
				padding-right: 0;
				display: flex;
				flex-wrap: nowrap;
				justify-content: center;
				.main-menu {
					width: auto;
					padding-left: 0;
				}
				#pav-mainnav {
					.first-text {
						display: none
					}
				}
				.seaarch-mob {
					position: initial;
					width: auto;
					padding-right: 15px;
					.search_block {
						padding-top: 5px;
					}
					#searchtop {
						display: none;
						&.search-activ {
							position: absolute;
							top: 177px;
							width: 100%;
							left: 0;
							padding: 0 40px;
							z-index: 1;
							display: flex;
						}
					}
					.toggle-btn-search {
						display: block;
						text-align: right;
						.btn-search {
							background: $wh;
							border: 0;
							padding: 4px 5px 0 5px;
						}
					}
				}
				.cart-compare-inner {
					&:after {
						width: 0;
						display: none;
					}
				}
			}
			.cart-compare-mob {
				padding: 0;
				width: auto;
				.cart-compare-inner {
					#compare-url {
						padding-right: 15px;
					}
				}
			}
		}
	}
	#powered {
		.row {
			.footer-name {
				display: flex;
				svg {
					width: 129px;
					padding-right: 13px;
				}
				.additional {
					width: auto;
					.btn-white {
						display: block;
					}
				}
			}
			&:last-child {
				.btn-white {
					display: block;
				}
			}
		}
	}
	.page-home {
		h1 {
			font-size: 20px !important;
			line-height: 24px !important;
			letter-spacing: 0.05em;
		}
		h2 {

			font-size: 24px !important;
			line-height: 28px !important;
			letter-spacing: 0.05em;
		}
		h3 {
			font-size: 20px !important;
			line-height: 24px !important;
		}
		.pav-categorytab {
			.owl-nav {
				display: none;
			}
		}
		.widget-products {
			.tab-content {
				padding: 0;
			}
			.tab-header {
				padding-bottom: 24px;
			}
		}
		.cat-carousel {
			.owl-stage-outer {
				width: 100%;
			}
			.owl-item {
				&.active {
					max-width: 218px;
					font-family: 'Rubik', sans-serif;
					text-align: center;
					color: $dg;
				}
			}
		}
		.main-column {
			.pav-container {
				&:nth-child(5), &:nth-child(7) {
					background-position: center;
				}
			}
		}
		.featured-categories {
			.panel-body {
				.feature-cat {
					width: 100%;
					&:nth-child(6n+1), &:nth-child(6n+6) {
						.image {
							.img-responsive {
								margin: auto;
							}
						}
					}
					&:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
						.image {
							& > a {
								left: 65px;
							}
						}
					}
				}
			}
		}
		#html-on-main-page {
			.text-block-el {
				flex-wrap: wrap;
				.text-block-svg {
					width: 100% !important;
					height: auto !important;
					min-height: auto;
					margin: 16px 0 0 0 !important;
					svg {
						width: 80px !important;
						height: 80px !important;
					}
				}
			}
		}
		.newsletter {
			padding-right: 0;
			.newsletter-inner {
				flex-direction: column;
				.img-block {
					width: 100%;
					img {
						border-radius: 10px 10px 0 0;
						width: 100%;
						margin: auto;
					}
				}
			}
		}
		.opacity-with-end {
			&:after {
				width: 100px;
				right: -50px;
				background: linear-gradient(265.6deg, #F9F9F9ED 54.81%, rgba(255, 255, 255, 0.07) 64.19%);
				background: -o-linear-gradient(265.6deg, #F9F9F9ED 54.81%, rgba(255, 255, 255, 0.07) 64.19%);
			}
		}
	}
	#pavo-footer-center, #powered {
		font-family: 'Rubik', sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		color: $d;
		.row {
			& > div {
				width: 100%;
			}
		}
	}
	.page-product {
		.rating-compare {
			padding-bottom: 8px;
			.title-product {
				font-size: 20px;
				line-height: 24px;
			}
			.prod_rating {
				.p1 {
					font-size: 14px !important;
					line-height: 16px !important;
					font-style: normal !important;
					font-weight: normal !important;
				}
			}
			.prod_rating {
				width: 100%;
				flex-wrap: nowrap;
				justify-content: space-between;
				padding-bottom: 7px;
				.sep {
					margin: -5px 0 0 0;
				}
			}
		}
		.product-view {
			padding: 0 9px;

			&.product-markdown-view {
				padding: 0;
			}
			#product {
				width: 100%;
				padding: 24px 0 16px 0;
				.btn-links {
					justify-content: space-around;
				}
			}
			.block-info {
				width: 100%;
			}
			.block-faq {
				.icon {
					width: 82px;
					max-height: 86px;
					margin: 0 13px 16px 0 !important;
				}
			}
		}
		.product-description {
			.container {
				padding: 0;
			}
		}
		h2 {
			font-size: 24px;
			line-height: 28px;
			letter-spacing: 0.05em;
		}
		.product-info-bg {
			margin: 24px 0 0 0;
		}
	}
	.page-category, .page-product-search, .page-product-special {
		.product-grid {
			.products-block {
				.product-block {
					margin: 8px 0 !important;
					width: calc(100% / 1);
				}
			}
		}
		.category-header {
			margin: 0 -15px;
			border-radius: 0;
			.grad_background {
				.sub_categories, .sub_category {
					a {
						font-size: 14px;
						line-height: 16px;
						text-align: center;
					}
				}
			}
		}
		.pagination {
			justify-content: space-around;
			padding: 12px 16px;
			width: 100%;
			li {
				width: 24px;
				height: 24px;
				padding: 0;
				margin: 0 3px;
			}
			.pagination_arrows {
				i {
					font-size: 22px;
					padding-top: 1px;
					padding-left: 1px;
				}
			}
			.no-decor-link {
				&:hover {
					background: transparent;
					border-radius: 0;
					cursor: default;
					a {
						color: inherit;
						cursor: default;
					}
				}
			}
		}
	}
	.page-checkout-buy {
		#content {
			h1 {
				font-size: 20px;
				line-height: 24px;
			}
			.container-total-item {
				.title {
					font-size: 20px !important;
					line-height: 24px !important;
				}
			}
			#checkout-form {
				.sub-title {
					font-family: 'Rubik', sans-serif;
					font-weight: 500 !important;
					font-size: 20px;
					line-height: 24px;
				}
				.sub-sub-title {
					font-family: 'Rubik', sans-serif;
					font-weight: 500;
					font-size: 18px !important;
					line-height: 24px !important;
				}
				#is_ip {
					.radio_custom {
						flex-direction: column;
						label {
							width: 100%;
							&:first-child {
								max-width: unset;
							}
						}
					}
				}
			}
		}
		#buy_form_container .row .col-md-7 {
			.shopping-cart-table {
				padding: 16px 16px 8px 8px;
				.product-item {
					display: block;
					.column-image {
						width: 25%;
						padding: 0;
					}
					.column-name {
						font-weight: normal;
						font-size: 12px;
						line-height: 18px;

						&.product-deleted {
							.btn-restore {
								width: 75%;
								margin-left: auto;
								display: block;
							}
							svg {
								margin-bottom: -5px;
							}
						}
					}
					.qty-price {
						width: 75%;
						margin-left: auto;
						.column-price, .column-total {
							font-weight: normal;
							font-size: 16px;
							line-height: 20px;
							span {
								min-width: 75px;
							}
						}
					}
				}
			}
		}
	}
	#news_list {
		.widget_news_item {
			width: 100%;
		}
	}
}

@media (max-width: 420px) {
	#header-layout {
		.logo-container {
			padding-left: 15px;
		}
		#topcontacts {
			.contacts_info {
				.worktime {
					font-size: 10px !important;
				}
			}
		}
	}
	.page-category, .page-product-search, .page-product-special {
		.product-filter {
			.filter-right {
				width: 100%;
				.selectric {
					width: 100%;
				}
			}
		}
		#mfilter-top {
			.mfilter-btn-box {
				width: 100%;
				.btn-show-filters {
					width: 100%;
				}
			}
			.mfilter-button-bottom {
				width: 100%;
				a {
					width: 100%;
					margin: 0;
					&:first-child {
						margin-bottom: 16px;
					}
				}
			}
		}
	}
	.page-home {
		.featured-categories {
			.panel-body {
				.feature-cat {
					&:nth-child(6n+1), &:nth-child(6n+6) {
						.image {
							.img-responsive {
								margin-left: -50%;
								margin-top: -130px;
							}
						}
					}
					&:nth-child(5) {
						.image {
							.img-responsive {
								margin-left: -73%;
								margin-top: -135px;
							}
						}
					}
					.image {
						height: 240px;
						.img-responsive {
							margin-left: -50%;
						}
					}
				}
			}
		}
		#html-on-main-page {
			padding-top: 24px;
		}
		.newsletter {
			padding: 32px 0 40px 0;
		}
		.main-column {
			.pav-container {
				padding-top: 0;
				padding-bottom: 43px;
				&:nth-child(9) {
					h2 {
						padding-top: 32px;
					}
				}
			}
		}
	}
	.page-product {
		.rating-compare {
			.compare {
				width: 50%;
				margin: 0 !important;
				justify-content: start;
			}
			.stock {
				padding: 0 !important;
			}
		}
	}
	.quick-view {
		#image-additional {
			display: none;
		}
		.product-meta {
			justify-content: center;
			.cart {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				button {
					width: 100%;
					padding-top: 0;
					margin: 10px 0 0 0;
				}
			}
			& > div {
				&:nth-child(3) {
					width: 100%;
				}
			}
		}
	}
}

@media (max-width: 375px) {
	.page-product {
		.block-faq {
			flex-wrap: wrap;
			.icon {
				position: relative;
				margin: 0 14px 14px 0;
				width: calc(100% / 2 - 14px);
				&:nth-child(2n) {
					margin: 0 0 14px 0;
				}
			}
		}
		#product {
			.btn-links {
				justify-content: normal !important;
			}
		}
	}
	.widget-products {
		.product-block {
			margin: 0;
		}
	}
	.success {
		width: 95%;
		left: 3%;
		margin-left: 0;
		.item-box {
			padding: 0;
			img {
				width: 150px;
			}
		}
	}
	.row-offcanvas-left {
		&.active {
			.sidebar-offcanvas {
				.panel-heading, a.panel-title, .cat-first-body a, .cat-two-body a {
					padding: 16px 24px !important;
					padding-right: 45px !important;
					font-style: normal !important;
					font-size: 16px !important;
					line-height: 20px !important;
					font-weight: normal !important;
				}
				.panel-heading {
					& > span.panel-title {
						font-weight: 500 !important;
						font-size: 18px !important;
						line-height: 24px !important;
					}
				}
			}
		}
	}
	.page-product .block-act .price .price-new, .quick-view .block-act .price .price-new {
		font-size: 30px;
	}
	.page-checkout-buy {
		h1 {
			padding-bottom: 24px;
		}
		.logo-contacts-box {
			margin: 0;
		}
		#sidebar-main {
			padding: 0;
		}
		#checkout-form {
			#personal-info, #delivery-info, #payment-info, #comment-bot {
				padding: 16px;
				margin-bottom: 32px;
			}
			#delivery-info {
				padding: 16px 0 0;
			}
		}
		#content {
			.shopping-cart-table {
				.product-item {
					.column-image {
						min-height: 145px;
					}
					.qty-price {
						flex-wrap: wrap !important;
						.column-price {
							flex-direction: row !important;
							flex-basis: 100% !important;
							.old {
								margin-left: 8px;
								order: 1;
							}
						}
						.column-quantity {
							padding-top: 8px;
						}
						.column-total {
							flex-basis: 60% !important;
							padding-left: 10px !important;
							padding-top: 10px;
						}
					}
				}
			}
		}
	}
	.page-home {
		.pav-container {
			&:nth-child(7) {
				.tab-header {
					width: -moz-min-content;
					width: min-content;
				}
			}
		}
		.featured-categories {
			.panel-body {
				.feature-cat {
					&:nth-child(2) {
						.image {
							.img-responsive {
								margin-left: -90%;
							}
						}
					}
					&:nth-child(6n+1), &:nth-child(6n+6) {
						.image {
							.img-responsive {
								margin-left: -69%;
							}
						}
					}
					&:nth-child(5) {
						.image {
							.img-responsive {
								margin-left: -89%;
							}
						}
					}
				}
			}
		}
		.cat-carousel {
			.sub_categories {
				a {
					font-size: 14px;
					line-height: 20px;
				}
			}
		}
		#html-on-main-page {
			.img-block {
				margin: 0;
				.svg-icon {
					width: 24px;
					height: 24px;
				}
			}
		}
		.newsletter {
			.img-block, img {
				border-radius: 0 !important;
			}
			#nl_form {
				.checkbox_custom {
					.input-control {
						margin-top: 0 !important;;
					}
				}
			}
		}
	}
	#header-layout {
		.header-top {
			.container-logo {
				justify-content: start;
				padding-left: 24px;
				.logo-container {
					margin: 0;
					padding-left: 0;
				}
			}
			.container-contacts {
				justify-content: start;
				padding-left: 12px;
				#topcontacts {
					margin: 0;
				}
			}
		}
		.header-menu {
			.container {
				justify-content: start;
				padding-left: 14px;
			}
		}
	}
	.breadcrumbs {
		.container {
			padding-left: 24px !important;
		}
	}
	.page-category, .page-product-search, .page-product-special {
		.h1_container {
			padding-left: 24px;
		}
	}
	#widget_news {
		.widget_news {
			.row {
				margin: 0 -15px;
			}
			&_item {
				margin-right: 0;
				margin-left: 0;

				&_img {
					img {
						max-width: 100%;
					}
				}
			}
		}
	}
	#news_list {
		.widget_news_item {
			padding-left: 0;
			padding-right: 0;
		}
	}
	#news_item {
		.panel-heading {
			.title {
				font-size: 28px;
			}
		}
	}
}

@media (max-width: 320px) {
	.page-home {
		.featured-categories {
			.panel-body {
				.feature-cat {
					.image {
						.img-responsive {
							margin-left: -76%;
							margin-top: -35px;
						}
					}
					&:nth-child(5) {
						.image {
							.img-responsive {
								margin-left: -128%;
							}
						}
					}
				}
			}
		}
	}
	.page-product {
		.product-view {
			.block-faq {
				.icon {
					margin-right: 5px !important;
				}
			}
		}
	}
}