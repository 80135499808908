$wh: 		#ffffff !default; //white
$whwg:		#fefefe !default; //white with grey
$tc: 		#4caf50 !default; //theme color
$tc2: 		#1a1a1a !default; //second theme color
$d:			#3c3c3c !default; //dark
$dg: 		#6f6f6f !default; //dark grey
$lg: 		#f2f2f2 !default; //light grey
$lg2: 		#efefef !default; //light grey 2
$lbg:		#f7f8f8 !default; //light background
$lbg2:		#e7e7e7 !default; //light background
$bl:		#169fdd !default; //blue
$lbl:		#8bcfee !default; //light blue
$lgr:		#a0d98e !default; //light green
$lgr2:		#60c364 !default; //light green 2
$r:			#ea2824 !default; //red
$blc:		#26a6d1 !default; //blue custom
$rc:		#e2574c !default; //red custom
$orc:		#f4b459 !default; //orange custom

$mg: #959595 !default;
$bk: #444 !default;
$ddg: #353536 !default;
$lb: #5c97bf !default;
$bc: #c4c4c4 !default;
$lbc: #ededed !default;
$b: #2d5cb8 !default;

$cdn-image-theme-path-base:		'https://cdn.ballushop.ru/image/';

$f: 'Open Sans', sans-serif !default;
$hf: 'Rubik', sans-serif !default;
$fa: "FontAwesome" !default;


@import "fonts";
@import "bootstrap";
@import "vars/opencart";
@import "vars/elements.vars";
@import "mixins/app";
@import "app/base";
@import "app/elements";
@import "app/form";
@import "app/layout";
@import "app/block";
@import "app/product";
@import "app/modules";
@import "app/menu";
@import "opencart/opencart-modules";
@import "opencart/opencart-pages";
@import "opencart/offcanvas";
@import "opencart/style";
@import "opencart/theme";

@import "opencart/modules/megamenu.scss";
@import "opencart/modules/sphinx.scss";

@import "app/custom";
@import "opencart/responsive";